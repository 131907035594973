export default function PrepareErrors(object) {
  if (object?.response?.data !== undefined) {
    object = object.response.data;
  }

  if (typeof object.error === 'string') {
    return {
      ...object,
      messages: [object.error]
    };
  }

  // if (Object.keys(object).includes('message')) {
  //   return {
  //     ...object,
  //     messages: [object.message]
  //   }
  // }

  if (object.errors === undefined) {
    return object;
  }

  let subjects = Object.keys(object.errors);
  let messages = [];

  subjects.forEach((subject) => {
    let errors = object.errors[subject];

    if (Array.isArray(errors) === true) {
      let resultString = `${capitalize(subject)} - ${errors.join(', ')}`;
      messages.push(resultString);
    } else {
      let fields = Object.keys(errors);

      fields.forEach((field) => {
        let fieldErrors = errors[field];
        let resultString = `Please agree with the Terms to sign up.`;

        if (field === 'terms_of_use_consent') {
          resultString = `Please agree with the Terms to sign up.`;
        }
        else if (field === 'reset_password_token') {
          resultString = `Password reset token is invalid or expired.`;
        }
        else if (typeof fieldErrors === 'string') {
          resultString = `${fieldErrors}`;
        } 
        else {
          resultString = `${capitalize(subject)} ${field} ${fieldErrors.join(', ')}`;
        }
        
        if (resultString === "password can't be blank") {
          resultString = 'Enter your password.'
        }

        messages.push(resultString);
      });
    }
  });
  return {
    ...object,
    messages: messages
  };
}

function capitalize(string) {
  return string && string[0].toUpperCase() + string.slice(1);
}