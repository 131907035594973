import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

export default function BodyStyles(props) {
  // font-size: 0.600vw; // current
  // font-size: 0.625vw; // 1600
  // font-size: 0.694444444vw; // 1440
  const location = useLocation();
  const matches = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    switch (props.contextName) {
      case 'landing':
        document.getElementsByTagName('html')[0].style.fontFamily = '"DM Sans", sans-serif';
        document.getElementsByTagName('html')[0].style.fontSize = matches ? '16px' : '0.600vw';
        document.body.style.fontFamily = '"DM Sans", sans-serif';
        document.body.style.fontSize = matches ? '16px' : '0.600vw';
        break;
      default:
        document.getElementsByTagName('html')[0].style.fontFamily = '"Montserrat", sans-serif';
        document.getElementsByTagName('html')[0].style.fontSize = '16px';
        document.body.style.fontFamily = '"Montserrat", sans-serif';
        document.body.style.fontSize = '16px';
    }
  }, [matches, location.pathname]);

  return null;
}

BodyStyles.propTypes = {
  contextName: PropTypes.string
};
