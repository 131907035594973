import React from 'react';
import Lottie from 'react-lottie';

import animationData from 'static/animations/loading_screen.json';

export default function LoadingScreen() {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  };

  return (
    <div
      style={{
        height: '100vh',
        position: 'absolute',
        zIndex: 999,
        width: '100%',
        background: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Lottie style={{ width: 65 }} options={animationOptions} />
    </div>
  );
}
