const baseURL = process.env.REACT_APP_BASE_URL;

// dashboard
const logoImage = `${baseURL}/assets/icons/logo_b.svg`;

const iconDashboardArrow = `${baseURL}/assets/icons/i_sidebar_arrow_right.svg`;
const iconDashboardAnalytics = `${baseURL}/assets/icons/i_sidebar_analytics.svg`;
const iconDashboardAnalyticsActive = `${baseURL}/assets/icons/i_sidebar_analytics_a.svg`;
const iconDashboardPulseSurveys = `${baseURL}/assets/icons/i_sidebar_pulse_surveys.svg`;
const iconDashboardPulseSurveysActive = `${baseURL}/assets/icons/i_sidebar_pulse_surveys_a.svg`;
const iconDashboardReports = `${baseURL}/assets/icons/i_sidebar_reports.svg`;
const iconDashboardReportsActive = `${baseURL}/assets/icons/i_sidebar_reports_a.svg`;
const iconDashboardSettings = `${baseURL}/assets/icons/i_sidebar_settings.svg`;
const iconDashboardSettingsActive = `${baseURL}/assets/icons/i_sidebar_settings_a.svg`;
const iconDashboardPraise = `${baseURL}/assets/icons/i_sidebar_praise.svg`;
const iconDashboardPraiseActive = `${baseURL}/assets/icons/i_sidebar_praise_active.svg`;
const iconDashboardRecs = `${baseURL}/assets/icons/i_sidebar_recs.svg`;
const iconDashboardRecsActive = `${baseURL}/assets/icons/i_sidebar_recs_active.svg`;

// pulse
const iconPulseIntro = `${baseURL}/assets/icons/i_pulse_intro.svg`;
const iconPulseInfo = `${baseURL}/assets/icons/i_pulse_info.svg`;
const iconPulseName = `${baseURL}/assets/icons/i_pulse_name.svg`;
const iconPulseSchedule = `${baseURL}/assets/icons/i_pulse_schedule.svg`;
const iconPulseTarget = `${baseURL}/assets/icons/i_pulse_target.svg`;
const iconPulseTeam = `${baseURL}/assets/icons/i_pulse_team.svg`;
const iconPulseFormInfo = `${baseURL}/assets/icons/i_info.svg`;
const iconPulseTeams = `${baseURL}/assets/icons/i_item_teams.svg`;
const iconPulseSlack = `${baseURL}/assets/icons/i_item_slack_new.svg`;
const iconPulseEmail = `${baseURL}/assets/icons/i_item_email.svg`;

const iconPulseTabSurvey = `${baseURL}/assets/icons/i_tab_surveys.svg`;
const iconPulseTabStopped = `${baseURL}/assets/icons/i_tab_stopped.svg`;
const iconPulseTabPaused = `${baseURL}/assets/icons/i_tab_paused.svg`;
const iconPulseTabDrafts = `${baseURL}/assets/icons/i_tab_drafts.svg`;
const iconPulseBtnInfo = `${baseURL}/assets/icons/i_btn_info.svg`;
const iconPulseItemEdit = `${baseURL}/assets/icons/i_item_survey_edit.svg`;
const iconPulseItemStop = `${baseURL}/assets/icons/i_item_survey_stop.svg`;
const iconPulseItemDestroy = `${baseURL}/assets/icons/i_item_survey_destroy.svg`;
const iconPermission = `${baseURL}/assets/icons/i_item_permissions.svg`;
const iconCheckMark = `${baseURL}/assets/icons/i_item_check.svg`;
const iconRefresh = `${baseURL}/assets/icons/i_item_refresh.svg`;

const iconUser = `${baseURL}/assets/icons/i_user.svg`;
const iconEmail = `${baseURL}/assets/icons/i_email.svg`;
const checkedCirle = `${baseURL}/assets/icons/i_round_checked.svg`;
// shared
const iconErrorRound = `${baseURL}/assets/icons/i_error_round.svg`;
const iconBtnRefresh = `${baseURL}/assets/icons/i_btn_refresh.svg`;
const iconInfoBlue = `${baseURL}/assets/icons/i_info_b.svg`;

const iconIdCard = `${baseURL}/assets/icons/i_id_card.svg`;
const iconGoogle = `${baseURL}/assets/icons/i_google.svg`;
const iconEditPencil = `${baseURL}/assets/icons/i_edit_pencil.svg`;
const userSpider = `${baseURL}/assets/icons/i_user_spider.svg`;
const infoGreyBG = `${baseURL}/assets/icons/i_info_grey_bg.svg`;
const infoRedFilled = `${baseURL}/assets/icons/i_info_red_filled.svg`;

export {
  logoImage,
  iconDashboardArrow,
  iconDashboardAnalytics,
  iconDashboardAnalyticsActive,
  iconDashboardPulseSurveys,
  iconDashboardPulseSurveysActive,
  iconDashboardReports,
  iconDashboardReportsActive,
  iconDashboardSettings,
  iconDashboardSettingsActive,
  iconDashboardPraise,
  iconDashboardPraiseActive,
  iconDashboardRecs,
  iconDashboardRecsActive,
  iconPulseIntro,
  iconPulseInfo,
  iconPulseName,
  iconPulseSchedule,
  iconPulseTarget,
  iconPulseTeam,
  iconPulseFormInfo,
  iconPulseTeams,
  iconPulseSlack,
  iconPulseEmail,
  iconPulseTabSurvey,
  iconPulseTabStopped,
  iconPulseTabPaused,
  iconPulseTabDrafts,
  iconPulseBtnInfo,
  iconPulseItemEdit,
  iconPulseItemStop,
  iconPulseItemDestroy,
  iconPermission,
  iconCheckMark,
  iconRefresh,
  iconErrorRound,
  iconBtnRefresh,
  iconInfoBlue,
  iconIdCard,
  iconUser,
  iconEmail,
  checkedCirle,
  iconGoogle,
  iconEditPencil, 
  userSpider,
  infoGreyBG,
  infoRedFilled
};
