import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import styles from 'features/landing/LandingDemoPage.module.scss';

import BodyBackground from 'features/shared/BodyBackground';
import BodyStyles from 'features/shared/BodyStyles';
import { ButtonLg } from 'features/shared/Buttons';
import CanonicalLink from 'features/shared/CanonicalLink';
import MetaTags from 'features/shared/MetaTags';

import imgDemoSuccess from 'static/landing/img_demo_success.png';

export default function LandingDemoThankYouPage() {
  const navigate = useNavigate();

  return (
    <div>
      <CanonicalLink link={`${process.env.REACT_APP_BASE_URL}/thank-you`} />
      <MetaTags
        title='Thank You | Beams.ai: Elevate Your Workforce Engagement'
        description='Thank you for connecting with us. Elevate your workforce engagement with our innovative solutions.'
      />
      <BodyStyles contextName={'landing'} />
      <BodyBackground color={'FFFFFF'} />
      <Box className={styles.successContainer}>
        <Box className={styles.successImage}>
          <img src={imgDemoSuccess} alt='demo' />
        </Box>
        <Box className={styles.successTitle}>Thank you!</Box>
        <Box className={styles.successSubtitle}>We are committed to making your team happier.</Box>
        <ButtonLg
          className={`${styles.button} ${styles.successButton}`}
          onClick={() => navigate('/')}>
          Back to Beams
        </ButtonLg>
      </Box>
    </div>
  );
}
