import {
  CONFIRM_USER_STARTED,
  CONFIRM_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_STARTED,
  GET_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  RESEND_USER_CONFIRMATION_CODE_SUCCESS,
  SET_USER_EMAIL,
  SET_USER_NAME,
  STORE_USER_TOKEN,
  UPDATE_USER_FAILURE,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS
} from 'redux/actionTypes/user';
import initialState from 'redux/initialState';

export default function user(state = initialState.user, action) {
  switch (action.type) {
    case GET_USER_STARTED:
      return {
        ...state,
        error: null,
        loading: true
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isLoggedIn: true,
        isRegistered: true,
        profile: {
          companies: [],
          ...state.profile,
          ...action.payload
        }
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case RESEND_USER_CONFIRMATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isRegistered: true,
        profile: {
          companies: [],
          ...state.profile,
          ...action.payload
        }
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isLoggedIn: true,
        isRegistered: true,
        profile: {
          companies: [],
          ...state.profile,
          ...action.payload
        }
      };
    case CONFIRM_USER_STARTED:
      return {
        ...state,
        loading: true
      };
    case CONFIRM_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: {
          companies: [],
          ...state.profile,
          isConfirmed: true
        }
      };
    case SET_USER_EMAIL:
    case SET_USER_NAME:
      return {
        ...state,
        profile: {
          companies: [],
          ...state.profile,
          ...action.payload
        }
      };

    case UPDATE_USER_STARTED:
      return {
        ...state,
        loading: true
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        profile: {
          companies: [],
          ...state.profile,
          ...action.payload
        }
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case STORE_USER_TOKEN:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token
      };

    case LOGOUT_USER_SUCCESS:
      return {
        isLoggedIn: false,
        ...action.payload
      };

    default:
      return state;
  }
}
