import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { Box, Button, Container, Stack } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import amplitudeHelper from 'helpers/Amplitude';

import iconCloseMenu from 'static/landing/i_landing_close_menu.svg';
import iconMenu from 'static/landing/i_landing_menu.svg';
import imgLogo from 'static/landing/logo.svg';

export default function LandingHeader(props) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const whyBeamsClickHandler = () => {
    setIsMenuOpen(false);
    try {
      amplitudeHelper.logEvent('why_beams_page_opened', {
        source: 'header'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  const handleGetButtonClick = () => {
    setIsMenuOpen(false);
    try {
      amplitudeHelper.logEvent('demo_button_clicked', {
        button_type: 'Main_corner'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
    window.gtag('event', 'demo_link_clicked');
    props.openCalendly();
  };

  const handleAuthLinkClick = (type) => {
    amplitudeHelper.logEvent(`${type}_button_clicked`, { source: 'header' });
    window.gtag('event', `${type}_button_clicked`);
  };

  const handleSignUpButtonClick = () => {
    amplitudeHelper.logEvent(`sign_up_button_clicked`, { source: 'header' });
    window.gtag('event', `sign_up_button_clicked`);
    navigate('/onboarding');
  };

  const resourceClickHandler = () => {
    setIsMenuOpen(false);
    try {
      amplitudeHelper.logEvent('blog_page_opened', {
        source: 'header'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  const pricingClickHandler = () => {
    setIsMenuOpen(false);
    try {
      amplitudeHelper.logEvent('pricing_page_opened', {
        source: 'header'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  return (
    <>
      <Box>
        {isMenuOpen && (
          <Box className={styles.mobileMenu}>
            <NavLink
              onClick={whyBeamsClickHandler}
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/about'
              end>
              Features
            </NavLink>
            <NavLink
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/pricing'
              end
              onClick={pricingClickHandler}>
              Pricing
            </NavLink>
            <NavLink
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/blog'
              end
              onClick={resourceClickHandler}>
              Resources
            </NavLink>
            <NavLink to='/onboarding' onClick={() => handleAuthLinkClick('sign_up')} end>
              Sign Up
            </NavLink>
            <NavLink to='/auth' onClick={() => handleAuthLinkClick('sign_in')} end>
              Sign In
            </NavLink>
          </Box>
        )}
        {/* <Box className={styles.banner}>
        <Container
          maxWidth='lg'
          style={{ maxWidth: '129.6rem', height: '6.0rem' }}
        >
          <Box className={styles.bannerWrapper}>
            <Box>
              Beams Beta is Live! Click now to receive your early{' '}
              <span>30-day free access</span>
            </Box>
            <Button onClick={onGetButtonClick}>Sign Me Up</Button>
          </Box>
        </Container>
      </Box> */}
        <Box className={styles.header} id='js-landing-header'>
          <Container maxWidth='lg' style={{ maxWidth: '117rem' }}>
            <Box className={styles.headerWrapper}>
              <Stack sx={{ flexDirection: 'row' }}>
                <Button
                  className={styles.headerMenuButton}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <img src={isMenuOpen ? iconCloseMenu : iconMenu} />
                </Button>
                <Link to='/' className={styles.headerLogo}>
                  <img alt='beams logo' src={imgLogo} style={{ width: '10rem' }} />
                </Link>
                <Box className={styles.headerMenu} style={{ marginRight: '3.0rem' }}>
                  <NavLink
                    onClick={whyBeamsClickHandler}
                    className={(state) => {
                      return state.isActive ? styles.activeLink : '';
                    }}
                    to='/about'
                    end>
                    Features
                  </NavLink>
                  <NavLink
                    className={(state) => {
                      return state.isActive ? styles.activeLink : '';
                    }}
                    to='/pricing'
                    end
                    onClick={pricingClickHandler}>
                    Pricing
                  </NavLink>
                  <NavLink
                    className={(state) => {
                      return state.isActive ? styles.activeLink : '';
                    }}
                    to='/blog'
                    end
                    onClick={resourceClickHandler}>
                    Resources
                  </NavLink>
                </Box>
              </Stack>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Link
                  to='/auth'
                  className={`${styles.authLink} ${styles.hideSM}`}
                  onClick={() => handleAuthLinkClick('sign_in')}>
                  Sign In
                </Link>
                <Button className={styles.headerButtonPrimary} onClick={handleSignUpButtonClick}>
                  Try for FREE
                </Button>
                <Button
                  className={styles.headerButtonSecondary}
                  onClick={handleGetButtonClick}
                  sx={{ marginLeft: '1.2rem' }}>
                  Book a Demo
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

LandingHeader.propTypes = {
  onAction: PropTypes.func,
  openCalendly: PropTypes.func
};
