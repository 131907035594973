import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import landingStyles from 'features/landing/Landing.module.scss';
import styles from 'features/landing/pages/resources/LandingTestLanding.module.scss';

import { ButtonLg } from 'features/shared/Buttons';
import CanonicalLink from 'features/shared/CanonicalLink';
import MetaTags from 'features/shared/MetaTags';
import ScrollToTopOnMount from 'features/shared/ScrollToTopOnMount';

import iconArrow from 'static/landing/i_landing_test_arrow.svg';
import imgSection1Sm from 'static/landing/img_landing_test_landing_sm.webp';
import imgSection1 from 'static/landing/img_landing_test_landing.webp';

const FAQ_ROW_DATA = [
  {
    title: 'What is the process for the test?',
    content:
      "It takes approximately 15 minutes to complete the test. Please select an image from the options below to conclude a story using the action that you believe is appropriate. Each subtest has its own set of rules for completing the images, and you'll find the instructions before each subtest."
  },
  {
    title: 'Why does every leader need to know his EI score?',
    content:
      'Emotional intelligence (EI) is crucial for effective leadership as it enables leaders to understand, manage, and regulate their own emotions while also being able to perceive, understand and influence the emotions of others. Leaders with high EI are better equipped to navigate complex interpersonal relationships, manage conflicts, and communicate effectively with their teams. They can also inspire and motivate their teams towards a common goal and create a positive work culture that fosters creativity and productivity. In short, leaders who possess high EI are more likely to create a healthy and productive work environment, resulting in better outcomes for their organization.'
  },
  {
    title: "What's the value of Beams EI Test results?",
    content:
      "Our Emotional intelligence test results can be incredibly valuable for team leaders as they provide insight into their own emotional intelligence levels and areas for improvement. By understanding their own emotional strengths and weaknesses, leaders can identify areas where they need to focus on personal and professional development. Additionally, the results of our EI test can help leaders better understand their team members' emotional needs and how to communicate more effectively with them. This can lead to a more harmonious work environment, increased motivation and productivity, and improved team relationships. Overall, the importance of EI test, lies in its ability to help team leaders become more self-aware, empathetic, and effective in their leadership roles."
  }
];

export default function LandingTestLanding() {
  const [sectionIndices, setSectionIndices] = useState({
    0: false,
    1: false,
    2: false
  });

  return (
    <Box>
      <CanonicalLink link={`${process.env.REACT_APP_BASE_URL}/tests/emotional-intelligence`} />
      <MetaTags
        title='Free Emotional Intelligence Test for Leaders | Beams.ai'
        description='Discover your Emotional Intelligence score and learn how to leverage your strengths to become a more effective leader.'
      />
      <ScrollToTopOnMount />
      <Box className={styles.section1}>
        <Box className={styles.section1Image}>
          <img className={landingStyles.hideSM} src={imgSection1} style={{ width: '126.8rem' }} />
          <img
            className={landingStyles.showSM}
            src={imgSection1Sm}
            style={{ width: '100%', marginBottom: 32 }}
          />
        </Box>
        <Box className={styles.section1Title}>
          Unlock Your Leadership Potential with our <span>Free Emotional Intelligence Test</span>
        </Box>
        <Box className={styles.section1Subtitle}>
          No credit card required. Receive personalized results straight to your inbox
        </Box>
        <Box className={styles.section1ButtonRow}>
          <Link style={{ textDecoration: 'none' }} to='/tests/emotional-intelligence/start'>
            <ButtonLg className={styles.section1Button}>Start the Test</ButtonLg>
          </Link>
        </Box>
      </Box>
      <Box className={styles.section2}>
        <Box className={styles.section2Title}>Frequently Asked Questions</Box>
        {FAQ_ROW_DATA.map((faqRow, index) => (
          <Box
            key={`collapsible-row-${index}`}
            className={`${styles.collapsibleRow} ${sectionIndices[index] && styles.active}`}>
            <Box
              className={styles.collapsibleRowTitle}
              onClick={() =>
                setSectionIndices({
                  ...sectionIndices,
                  [index]: !sectionIndices[index]
                })
              }>
              {faqRow.title}
              <img src={iconArrow} className={styles.collapsibleRowIcon} />
            </Box>
            <Box className={styles.collapsibleRowContent}>{faqRow.content}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
