import React from 'react';

import { Box } from '@mui/material';

import styles from 'features/landing/LandingCookiesPopup.module.scss';

import amplitudeHelper from 'helpers/Amplitude';

export default function LandingCookiesPopup() {
  const handleButtonClick = (type) => {
    if (type === 'agree') {
      localStorage.setItem('accept_cookies', 'true');
      amplitudeHelper.logEvent('cookies_accepted', { result: true });
    } else {
      localStorage.setItem('accept_cookies', 'false');
      amplitudeHelper.logEvent('cookies_accepted', { result: false });
    }

    window.location.reload();
  };

  if (localStorage.getItem('accept_cookies') !== null) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.wrapperBackground}></Box>
      <Box className={styles.card}>
        <Box>
          We use cookies. By continuing to browse the site you are agreeing to our use of cookies.
        </Box>
        <Box className={styles.buttonWrapper}>
          <Box className={`${styles.button}`} onClick={() => handleButtonClick('deny')}>
            Deny
          </Box>
          <Box className={`${styles.button}`} onClick={() => handleButtonClick('agree')}>
            Agree
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
