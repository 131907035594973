import {
  CHANGE_CURRENT_COMPANY_ID,
  CREATE_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_STARTED,
  GET_COMPANY_SUCCESS
  // CREATE_PULSE_FAILURE
} from 'redux/actionTypes/company';
import initialState from 'redux/initialState';

export default function company(state = initialState.company, action) {
  switch (action.type) {
    case GET_COMPANY_STARTED:
      return {
        ...state,
        error: null,
        loading: true
      };
    case CREATE_COMPANY_SUCCESS:
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: false
      };
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CHANGE_CURRENT_COMPANY_ID:
      return {
        ...state,
        id: action.payload
        // TODO: Name
      };
    default:
      return state;
  }
}
