import React, { useEffect, useRef, useState } from 'react';

import { Box, Container, Grid, useMediaQuery } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';
import sharedStyles from 'features/shared/Shared.module.scss';

import iconForbes from 'static/landing/i_forbes.png';
import iconGallup from 'static/landing/i_gallup.png';
import iconHarvard from 'static/landing/i_harvard.png';
import imgSlideArrowLeft from 'static/landing/img_c_arrow_left.svg';
import imgSlideArrowRight from 'static/landing/img_c_arrow_right.svg';
import imgFeature1 from 'static/landing/img_feature_1.webp';
import imgFeature2 from 'static/landing/img_feature_2.webp';
import imgFeature3 from 'static/landing/img_feature_3.webp';
import imgTeamScreen1 from 'static/landing/l_feature_1.webp';
import imgTeamScreen2 from 'static/landing/l_feature_2.webp';
import imgTeamScreen3 from 'static/landing/l_feature_3.webp';
import imgTeamScreen4 from 'static/landing/l_feature_4.webp';

export default function LandingSection2() {
  const matches = useMediaQuery('(max-width:600px)');
  const images = [imgTeamScreen1, imgTeamScreen2, imgTeamScreen3, imgTeamScreen4];
  const titles = [
    'Ready-to-use Pulse Surveys',
    'Powerful Analytics',
    'Personal Employee Recommendations',
    'Gratitude Tool'
  ];
  const subtitles = [
    'Assess engagement trends within your team through regular and practical pulse surveys. Beams provides a safe and anonymous space for team members to express their needs and concerns.',
    "Beams analyzes survey results, providing managers with comprehensive suggestions for improvement. With detailed analytics, you can discover your team's strengths and areas for improvement, and take timely action to prevent burnout and employee turnover.",
    'Employees receive personalized recommendations based on survey results. This helps to unlock the full potential of your team and encourages them to bring their best selves to work.',
    'The Beams Gratitude tool makes it easy to show appreciation for your team. By ritualizing the practice of thanking individuals, you can help team members feel better at work and perform their best.'
  ];
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [isIntersecting, setIntersecting] = useState(false);

  const triggerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(triggerRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleArrowClick = (direction) => {
    let index = currentSectionIndex + (direction === 'right' ? 1 : -1);
    if (index > images.length - 1) {
      index = 0;
    } else if (index < 0) {
      index = images.length - 1;
    }

    setCurrentSectionIndex(index);
  };

  return (
    <>
      <Box ref={triggerRef} style={{ position: 'relative' }} className={styles.section2}>
        {images.map((image, index) => {
          return <link key={`section-2-preload-${index}`} rel='preload' as='image' href={image} />;
        })}
        <Container maxWidth='lg' style={{ maxWidth: '119rem' }}>
          <h2 className={styles.sectionTitle} style={{ margin: 0 }}>
            Listen, measure, and take action!
          </h2>
          <Box className={styles.section2Subtitle}>
            Unlock your team&apos;s potential with our powerful features
          </Box>
          <Box className={styles.section2Wrapper}>
            <Box className={styles.section2ContentTabs}>
              {titles.map((title, index) => {
                return (
                  <Box
                    key={`section-2-tab-${index}`}
                    className={index === currentSectionIndex && styles.active}
                    onClick={() => setCurrentSectionIndex(index)}>
                    {title}
                  </Box>
                );
              })}
            </Box>
            <Box
              className={styles.section2ContentWrapper}
              style={{
                borderTopLeftRadius: currentSectionIndex !== 0 ? '6rem' : '0',
                borderBottomLeftRadius: currentSectionIndex !== 3 ? '6rem' : '0'
              }}>
              <Box className={styles.section2TextWrapper}>
                <Box className={styles.section2FeatureTitle} style={{ zIndex: 2 }}>
                  {titles[currentSectionIndex]}
                </Box>
                <Box className={`${styles.section2FeatureBody} ${styles.fadeIn}`}>
                  {subtitles[currentSectionIndex]}
                </Box>
              </Box>
              <Box className={styles.section2ImageControlsWrapper}>
                <Box
                  className={`${styles.section5ArrowWrapper} ${styles.showSM} ${sharedStyles.mr5}`}
                  onClick={() => handleArrowClick('left')}>
                  <img alt='arrow left' src={imgSlideArrowLeft} />
                </Box>
                <Box
                  className={`${styles.section2ImageWrapper} ${
                    currentSectionIndex % 2 === 0 ? styles.fadeIn : styles.fadeIn2
                  }`}>
                  <img alt='feature illustration' src={images[currentSectionIndex]} />
                </Box>
                <Box
                  className={`${styles.section5ArrowWrapper} ${styles.showSM} ${sharedStyles.ml5}`}
                  onClick={() => handleArrowClick('right')}>
                  <img alt='arrow right' src={imgSlideArrowRight} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container className={styles.container1296}>
        <Box style={{ width: '100%' }}>
          <h2 className={styles.section1TitleSm}>
            How employee engagement relates
            <br />
            to your business
          </h2>
          <Grid container className={styles.section1GridContainer} justifyContent='space-between'>
            <Grid item className={styles.section1FeatureFlex}>
              <img alt='feature icon' className={styles.img} src={imgFeature1} />
              <Box>
                <Box className={styles.section1FeatureTitle}>63% of employees</Box>
                <Box className={styles.section1FeatureBody}>
                  {/* worldwide experience burnout resulting in higher turnover <a rel="noreferrer" target="_blank" href='https://www.forbes.com/sites/tracybrower/2022/07/24/burnout-is-a-worldwide-problem-5-ways-work-must-change/'><img className={styles.iconInfo} src={iconInfo} aria-describedby={id} /></a> */}
                  <div>worldwide experience burnout resulting in higher turnover</div>
                  <img
                    src={iconForbes}
                    className={`${styles.iconForbes} ${styles.hideSM}`}
                    style={{ width: '8rem', marginTop: '0.1rem' }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item className={styles.section1FeatureFlex}>
              <img alt='feature icon' className={styles.img} src={imgFeature2} />
              <Box>
                <Box className={styles.section1FeatureTitle}>21% higher</Box>
                <Box className={styles.section1FeatureBody}>
                  <div>productivity is achieved by companies with strongly engaged teams</div>
                  <img
                    src={iconGallup}
                    className={`${styles.iconGallup} ${styles.hideSM}`}
                    style={{ width: '8.5rem', marginTop: '1.6rem' }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item className={styles.section1FeatureFlex}>
              <img alt='feature icon' className={styles.img} src={imgFeature3} />
              <Box>
                <Box className={styles.section1FeatureTitle}>41% of employees</Box>
                <Box className={styles.section1FeatureBody}>
                  <div>report not working hard enough because they&apos;re unhappy</div>
                  <img
                    src={iconHarvard}
                    className={`${styles.iconHarvard} ${styles.hideSM}`}
                    style={{ width: '7.4rem', marginTop: '1.9rem' }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className={`${styles.section1BrandsWrapper} ${styles.showSM}`}>
            <img
              src={iconForbes}
              className={`${styles.iconForbes}`}
              style={{ width: '80px', marginTop: -14 }}
            />
            <img src={iconGallup} className={`${styles.iconGallup}`} style={{ width: '85px' }} />
            <img src={iconHarvard} className={`${styles.iconHarvard}`} style={{ width: '74px' }} />
          </Box>
        </Box>
      </Container>
    </>
  );
}
