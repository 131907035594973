import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';

import { Input } from '@mui/material';

import styles from 'features/shared/Shared.module.scss';

const PrimaryInput = (props) => {
  const { className, validationError, hidevalidationerrortext, ...newProps } = props; // eslint-disable-line

  return (
    <Fragment>
      <Input
        className={`
          ${props.className}
          ${styles.input}
          ${props.validationError ? styles.inputError : ''}
        `}
        disableUnderline
        {...newProps}
      />
      {props.validationError && hidevalidationerrortext !== true && (
        <div
          className={`${styles.errorText} ${styles.mt10}`}
          style={{
            textAlign: props.validationalignment || 'left',
            fontSize: 16,
            marginTop: 8,
            marginBottom: 20
          }}>
          {props.validationError}
        </div>
      )}
    </Fragment>
  );
};

PrimaryInput.propTypes = {
  className: PropTypes.string,
  validationError: PropTypes.string,
  hidevalidationerrortext: PropTypes.any,
  validationalignment: PropTypes.string
};

export { PrimaryInput };
