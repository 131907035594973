import React from 'react';

import { Box, Container } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import trustBadgeCapterra from 'static/landing/trust_badge_capterra.svg';
import trustBadgeCrozdesk from 'static/landing/trust_badge_crozdesk.svg';
import trustBadgeGetApp from 'static/landing/trust_badge_get_app.svg';
import trustBadgeSA from 'static/landing/trust_badge_sa.svg';

export default function LandingTrustBadges() {
  return (
    <Container maxWidth='lg' className={styles.container995} sx={{ padding: 0 }}>
      <Box className={styles.trustBadges}>
        <Box className={styles.trustBadgesBox}>
          <img src={trustBadgeGetApp} className={styles.trustBadgesItem} />
        </Box>
        <Box className={styles.trustBadgesBox}>
          <img src={trustBadgeSA} className={styles.trustBadgesItem} />
        </Box>
        <Box className={styles.trustBadgesBox}>
          <img src={trustBadgeCapterra} className={styles.trustBadgesItem} />
        </Box>
        <Box className={styles.trustBadgesBox}>
          <img src={trustBadgeCrozdesk} className={styles.trustBadgesItem} />
        </Box>
      </Box>
    </Container>
  );
}
