import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { changeCurrentCompanyId, getCompanySuccess } from 'redux/actions/company';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import 'App.scss';

import { noindexBlogPages } from 'helpers';

import LoadingScreen from 'features/shared/LoadingScreen';
import Landing from 'features/landing/Landing';
import LandingDemoThankYouPage from 'features/landing/LandingDemoThankYouPage';
import LandingPrivacyPolicyPage from 'features/landing/LandingPrivacyPolicyPage';
import LandingTermsOfUsePage from 'features/landing/LandingTermsOfUsePage';
import MemoizedLandingMainPage from 'features/landing/pages/main/LandingMainPage';
// import LandingMainPage from 'features/landing/pages/main/LandingMainPage';
import LandingTestLanding from 'features/landing/pages/resources/LandingTestLanding';

import amplitudeHelper from 'helpers/Amplitude';
import useNotifier from 'helpers/useNotifier';

// app components
const Error404Page = React.lazy(() => import('pages/Error404Page'));
const InviteMainScreen = React.lazy(() => import('features/invite/InviteMainScreen'));
const JoinThanksScreen = React.lazy(() => import('features/join/JoinThanksScreen'));
const LandingResourcesIndexPage = React.lazy(() =>
  import('features/landing/pages/resources/LandingResourcesIndexPage')
);
const LandingTestLayout = React.lazy(() =>
  import('features/landing/pages/resources/LandingTestLayout')
);
const LandingPricingPage = React.lazy(() =>
  import('features/landing/pages/pricing/LandingPricingPage')
);
const LandingAboutPage = React.lazy(() => import('features/landing/pages/about/LandingAboutPage'));
const LandingContactPage = React.lazy(() =>
  import('features/landing/pages/contact/LandingContactPage')
);
const LandingDemoPage = React.lazy(() => import('features/landing/LandingDemoPage'));
const Onboarding = React.lazy(() => import('features/onboarding/Onboarding'));
const SurveyLayout = React.lazy(() => import('features/employeeSurvey/SurveyLayout'));
const RestrictedRoutes = React.lazy(() => import('routes/RestrictedRoutes'));
const RecoveryEmailForm = React.lazy(() => import('features/passwordRecovery/RecoveryEmailForm'));
const RecoveryNewPasswordForm = React.lazy(() =>
  import('features/passwordRecovery/RecoveryNewPasswordForm')
);
const RedirectManager = React.lazy(() => import('features/shared/RedirectManager'));

const App = () => {
  useNotifier();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.company.id);
  const userData = useSelector((state) => state.user);
  const [displayLoading, setDisplayLoading] = useState(
    window.location.href.includes('dashboard') ||
      [null, 'null'].includes(localStorage.getItem('token')) === false
  );

  useEffect(() => {
    setTimeout(() => {
      if (displayLoading) {
        setDisplayLoading(false);
      }
    }, 800);
  }, []);

  useEffect(() => {
    if (!displayLoading) {
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get('utm_source');
      const utmMedium = urlParams.get('utm_medium');
      const utmCampaign = urlParams.get('utm_campaign');

      if (utmSource != null) {
        amplitudeHelper.logUserProperty('utm_source', utmSource);
        amplitudeHelper.updateCommonParams({ utm_source: utmSource });
      }

      if (utmMedium != null) {
        amplitudeHelper.logUserProperty('utm_medium', utmMedium);
        amplitudeHelper.updateCommonParams({ utm_medium: utmMedium });
      }

      if (utmCampaign != null) {
        amplitudeHelper.logUserProperty('utm_campaign', utmCampaign);
        amplitudeHelper.updateCommonParams({ utm_campaign: utmCampaign });
      }
      try {
        amplitudeHelper.logEvent('landing_opened', {
          page_type: currentPageSelector(userData)
        });
      } catch (e) {
        console.log('amplitudeHelper ERROR: ', e);
      }
    }
  }, [displayLoading]);

  let currentCompany = userData.profile?.accessible_company_ids?.find(
    (company) => Number(company) === Number(companyId)
  );
  currentCompany = userData.profile?.companies?.find((company) => company.id === currentCompany);

  useEffect(() => {
    if (currentCompany !== undefined) {
      dispatch(getCompanySuccess(currentCompany));
    }
  }, [currentCompany]);

  amplitudeHelper.updateCommonParams({
    company_name: currentCompany?.title,
    company_id: currentCompany?.id
  });

  useEffect(() => {
    if (localStorage.getItem('uniqueUserId') === null) {
      localStorage.setItem('uniqueUserId', (Math.random() + 1).toString(36).substring(7));
    }
  }, []);

  // Retrieve user company if companyId == null
  useEffect(() => {
    console.log('retrieve user company', companyId);
    if (companyId === null || companyId === undefined) {
      console.log('retrieve user company');
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/companies`,
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then((resp) => {
          switch (resp.status) {
            case 200:
              var companies = resp.data.data;

              if (companies.length > 0) {
                const lastCompanyId = localStorage.getItem('lastCompanyId');

                const lastCompany =
                  companies.find((company) => String(company.id) === lastCompanyId) ||
                  companies[companies.length - 1];

                if (lastCompany) {
                  dispatch(changeCurrentCompanyId(lastCompany.id));
                }
              }
              break;
            case 401:
              break;
          }
        })
        .catch((error) => {
          switch (error.response?.status) {
            case 422: {
              break;
            }
          }
        });
    }
  }, []);

  return (
    <div className='App'>
      <div id='modal' />
      <Suspense fallback={<LoadingScreen />}>
        {displayLoading && <LoadingScreen />}
        <Router>
          <RedirectManager />
          <Routes>
            {/* Start of routes available to search bots */}
            <Route
              exact
              path='/'
              element={
                <Landing>
                  <MemoizedLandingMainPage />
                </Landing>
              }
            />
            <Route
              exact
              path='/about'
              element={
                <Landing>
                  <LandingAboutPage />
                </Landing>
              }
            />

            <Route
              exact
              path='/contact'
              element={
                <Landing>
                  <LandingContactPage />
                </Landing>
              }
            />
            <Route
              exact
              path='/pricing'
              element={
                <Landing>
                  <LandingPricingPage />
                </Landing>
              }
            />
            <Route exact path='/demo' element={<LandingDemoPage />} />
            <Route exact path='/thank-you' element={<LandingDemoThankYouPage />} />
            <Route
              exact
              path='/tests/emotional-intelligence/start'
              element={<LandingTestLayout />}
            />
            <Route
              exact
              path='/tests/emotional-intelligence'
              element={
                <Landing>
                  <LandingTestLanding />
                </Landing>
              }
            />
            <Route exact path='/onboarding' element={<Onboarding />} />
            {/* End of routes available to search bots */}
            <Route
              exact
              caseSensitive
              path='/blog'
              element={
                <Landing>
                  <LandingResourcesIndexPage />
                </Landing>
              }
            />
            <Route path={'/Blog'} caseSensitive element={<Navigate to='/blog' />} />
            <Route
              exact
              path='/privacy-policy'
              element={
                <Landing>
                  <LandingPrivacyPolicyPage />
                </Landing>
              }
            />
            <Route
              exact
              path='/terms-of-service'
              element={
                <Landing>
                  <LandingTermsOfUsePage />
                </Landing>
              }
            />
            <Route path={'/company/:companyId/join/thanks'} element={<JoinThanksScreen />} />
            <Route path={'/company/:companyId/invite/:code'} element={<InviteMainScreen />} />
            <Route path={'/surveys/:surveyUUID'} element={<SurveyLayout />} />
            <Route path={'/company/:companyId/invite/'} element={<InviteMainScreen />} />
            <Route path={'/recovery'} element={<RecoveryEmailForm />} />
            <Route path={'/new_password'} element={<RecoveryNewPasswordForm />} />
            {noindexBlogPages.map((path) => (
              <Route key={path} path={path} element={<Error404Page />} />
            ))}
            <Route path='/404' element={<Error404Page />} />
            <Route path='*' element={<RestrictedRoutes />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

const currentPageSelector = (userData) => {
  const currentPathname = window.location.pathname;
  if (userData.isLoggedIn) {
    return 'website';
  }
  if (currentPathname === '/about') {
    return 'features';
  }
  if (currentPathname.includes('blog')) {
    return 'resources';
  }
  if (currentPathname === '/contact') {
    return 'contact';
  }
  if (currentPathname === '/pricing') {
    return 'pricing';
  }
  return 'main';
};

export default App;
