import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';

import styles from 'features/landing/LandingCTAButtonGroup.module.scss';

import amplitudeHelper from 'helpers/Amplitude';

export default function LandingCTAButtonGroup({
  center = false,
  placeForAmplitude,
  forDarkBackground = false,
  onClickGetDemo,
  showInfo = false
}) {
  const navigate = useNavigate();

  const handleClickGetDemoButton = () => {
    onClickGetDemo();
    try {
      amplitudeHelper.logEvent('demo_button_clicked', {
        button_type: placeForAmplitude
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  const handleClickSignUpButton = () => {
    amplitudeHelper.logEvent('sign_up_button_clicked', {
      source: placeForAmplitude
    });
    navigate('/onboarding');
  };

  return (
    <Stack>
      <Stack className={styles.buttonBox} sx={{ justifyContent: center ? 'center' : 'flex-start' }}>
        <Button
          className={
            forDarkBackground
              ? clsx(styles.primaryButton, styles.contrastColor)
              : styles.primaryButton
          }
          onClick={handleClickSignUpButton}>
          Try for FREE
        </Button>
        <Button
          className={
            forDarkBackground
              ? clsx(styles.secondaryButton, styles.contrastColor)
              : styles.secondaryButton
          }
          onClick={handleClickGetDemoButton}>
          Book a Demo
        </Button>
      </Stack>
      {showInfo && (
        <Box
          className={styles.infoText}
          sx={{
            textAlign: center ? 'center' : 'left',
            color: forDarkBackground ? '#cecece' : '#757575'
          }}>
          No credit card required. Free plan includes all features.
        </Box>
      )}
    </Stack>
  );
}

LandingCTAButtonGroup.propTypes = {
  onClickGetDemo: PropTypes.func,
  forDarkBackground: PropTypes.bool,
  center: PropTypes.bool,
  placeForAmplitude: PropTypes.string,
  showInfo: PropTypes.bool
};
