import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Button, CircularProgress } from '@mui/material';

import styles from 'features/shared/Shared.module.scss';

const iconArrowBack = `${process.env.REACT_APP_BASE_URL}/assets/icons/i_arrow_back.svg`;
const iconArrowBackW = `${process.env.REACT_APP_BASE_URL}/assets/icons/i_arrow_back_w.svg`;
const iconGoogleLogo = `${process.env.REACT_APP_BASE_URL}/assets/icons/i_google_logo.svg`;

const ButtonSm = (props) => {
  const { className, ...newProps } = props; // eslint-disable-line

  return <Button className={`${styles.buttonSm} ${props.className}`} {...newProps} />;
};

ButtonSm.propTypes = {
  className: PropTypes.string
};

const ButtonLg = (props) => {
  const { className, ...newProps } = props; // eslint-disable-line

  return (
    <Button
      variant='text'
      className={`
        ${props.className}
        ${styles.button}
        ${styles.buttonLg}
        ${styles.fullWidth}
        ${styles.buttonRounded}
      `}
      {...newProps}>
      <div>{props.children}</div>
    </Button>
  );
};

ButtonLg.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const GoogleButtonLg = (props) => {
  const { className, ...newProps } = props; // eslint-disable-line

  return (
    <Button
      variant='text'
      className={`
        ${props.className}
        ${styles.button}
        ${styles.buttonLg}
        ${styles.fullWidth}
        ${styles.buttonRounded}
        ${styles.buttonGoogle}
      `}
      {...newProps}>
      <link rel='preload' href={iconGoogleLogo} as='image' />
      <img width='40' height='40' src={iconGoogleLogo} style={{ marginRight: 7 }} />
      <div>Sign in with Google</div>
    </Button>
  );
};

GoogleButtonLg.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const SpinnerButton = (props) => {
  const { className, ...newProps } = props; // eslint-disable-line

  return (
    <Button
      variant='text'
      className={`${props.className} ${styles.button} ${styles.fullWidth} ${styles.buttonRounded}`}
      {...newProps}>
      <CircularProgress
        style={{
          transition: 'all 0.1s ease-out',
          marginRight: newProps.disabled ? 8 : -24,
          opacity: newProps.disabled ? 1 : 0
        }}
        size={24}
        thickness={7}
        color='inherit'
      />
      <div>{props.children}</div>
    </Button>
  );
};

SpinnerButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const BackArrowButton = (props) => {
  const { className, ...newProps } = props; // eslint-disable-line

  return (
    <Button disableRipple className={styles.backButton} {...newProps}>
      <img width={30} src={newProps?.style?.color === '#FFF' ? iconArrowBackW : iconArrowBack} />
      {props.children}
    </Button>
  );
};

BackArrowButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const SwitchButton = (props) => {
  const { className, ...newProps } = props; // eslint-disable-line
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setSelectedOption(props.value || props.firstOption);
  }, [props.firstOption]);

  const handleOptionClick = (value) => {
    // setSelectedOption(value);
    props.onChange(value);
  };

  return (
    <Box className={styles.switchButtonWrapper}>
      <Button
        disableRipple
        className={`
          ${props.value === props.firstOption && styles.switchButtonSelectedOption}
          ${styles.switchButtonLeftOption}
          ${styles.switchButtonOption}
        `}
        onClick={() => handleOptionClick(props.firstOption)}
        {...newProps}>
        {props.firstOption}
      </Button>
      <Button
        disableRipple
        className={`
          ${props.value === props.secondOption && styles.switchButtonSelectedOption}
          ${styles.switchButtonRightOption}
          ${styles.switchButtonOption}
        `}
        onClick={() => handleOptionClick(props.secondOption)}
        {...newProps}>
        {props.secondOption}
      </Button>
    </Box>
  );
};

SwitchButton.propTypes = {
  className: PropTypes.string,
  firstOption: PropTypes.string,
  secondOption: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export { ButtonSm, ButtonLg, SpinnerButton, BackArrowButton, SwitchButton, GoogleButtonLg };
