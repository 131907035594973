import PropTypes from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';

import { Box, Container, Grid, useMediaQuery } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import LandingCTAButtonGroup from 'features/landing/LandingCTAButtonGroup';
import LandingTrustBadges from 'features/landing/LandingTrustBadges';

import animationData from 'static/animations/landing_title_v1.json';
import imgSection from 'static/landing/img_section_1.webp';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData
};

export default function LandingSection1(props) {
  const matchSM = useMediaQuery('(max-width:600px)');

  return (
    <Box className={styles.section1Wrapper}>
      <Container maxWidth='lg' className={styles.container1296}>
        <Grid container className={styles.section1Grid} justifyContent='space-between'>
          <Grid
            item
            width='100%'
            sx={{ '@media (max-width:600px)': { textAlign: 'center' }, paddingTop: '3rem' }}>
            <Box className={styles.titleBox}>
              <Lottie
                style={
                  matchSM
                    ? { marginLeft: '-8%' }
                    : {
                        height: '20rem',
                        marginLeft: '-4.4rem',
                        width: '49rem',
                        marginTop: '-1rem'
                      }
                }
                options={animationOptions}
              />
            </Box>
            <Box className={styles.section1Subtitle}>
              Easily integrated platform for hearing, recognizing, and engaging your teams.
            </Box>
            <Box className={styles.section1CTAButtonBox}>
              <LandingCTAButtonGroup
                onClickGetDemo={props.onOpenCalendly}
                placeForAmplitude='main_up'
                showInfo
              />
            </Box>
          </Grid>
          <Grid item display='flex' alignItems='center'>
            <Box className={styles.section1ImageWrapper}>
              <img alt='man with a laptop' style={{ width: '100%' }} src={imgSection} />
              {/* <Lottie
                style={{position: 'absolute', top: 0, left: 0, width: '600px'}}
                options={bubbleAnimationOptions}
              /> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <LandingTrustBadges />
    </Box>
  );
}

LandingSection1.propTypes = {
  onAction: PropTypes.func,
  onOpenCalendly: PropTypes.func
};
