import { useEffect } from 'react'

export default function CanonicalLink({link}) {
  useEffect(() => {
    document.head.insertAdjacentHTML(
     'afterbegin',
      `<link rel="canonical" href="${link}" />`
    );

    return ()=>{
      document.querySelector('link[rel="canonical"]').remove();
    }
  }, [link]);

  return null;
}
