import { enqueueSnackbar } from 'redux/actions/toast';
import { getCurrentUserSuccess } from 'redux/actions/user';
import {
  CHANGE_CURRENT_COMPANY_ID,
  CREATE_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_STARTED,
  GET_COMPANY_SUCCESS
} from 'redux/actionTypes/company';

import amplitudeHelper from 'helpers/Amplitude';
import PrepareErrors from 'helpers/PrepareErrors';

const getCompanyStarted = () => ({ type: GET_COMPANY_STARTED });
const getCompanySuccess = (company) => ({
  type: GET_COMPANY_SUCCESS,
  payload: { ...company }
});
const createCompanySuccess = (company) => ({
  type: CREATE_COMPANY_SUCCESS,
  payload: { ...company }
});
const getCompanyFailure = (error) => ({
  type: GET_COMPANY_FAILURE,
  payload: { ...error }
});

const createCompany = (data, setCompanyAndNotify) => async (dispatch) => {
  dispatch(getCompanyStarted());

  fetch(`${process.env.REACT_APP_API_URL}/companies`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          amplitudeHelper.logEvent('company_created', {
            company_id: json.id,
            company_name: json.title
          });

          dispatch(createCompanySuccess(json));
          dispatch(getCurrentUserSuccess());
          if (setCompanyAndNotify) {
            dispatch(changeCurrentCompanyId(json.id));
            dispatch(
              enqueueSnackbar({
                message: `Workspace '${json.title}' was successfully created.`,
                options: {
                  key: new Date().getTime() + Math.random(),
                  anchorOrigin: { vertical: 'top', horizontal: 'center' }
                }
              })
            );
          }
        });
      } else {
        response.json().then((error) => {
          dispatch(getCompanyFailure(PrepareErrors(error)));
        });
      }
    })
    .catch((error) => {
      dispatch(getCompanyFailure(PrepareErrors({ error: error.message })));
    });
};

const updateCompany = (id, data) => async (dispatch) => {
  dispatch(getCompanyStarted());

  fetch(`${process.env.REACT_APP_API_URL}/companies/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token')
    },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(createCompanySuccess(json));
          dispatch(getCurrentUserSuccess());
        });
      } else {
        response.json().then((error) => {
          dispatch(getCompanyFailure(PrepareErrors(error)));
        });
      }
    })
    .catch((error) => {
      dispatch(getCompanyFailure(PrepareErrors({ error: error.message })));
    });
};

const changeCurrentCompanyId = (companyId) => (dispatch) => {
  localStorage.setItem('lastCompanyId', companyId);

  dispatch({
    type: CHANGE_CURRENT_COMPANY_ID,
    payload: companyId
  });
};

export { createCompany, updateCompany, changeCurrentCompanyId, getCompanySuccess };
