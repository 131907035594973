import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Fade } from '@mui/material';

import styles from 'features/shared/Shared.module.scss';

import BodyBackground from 'features/shared/BodyBackground';
import BodyStyles from 'features/shared/BodyStyles';
import { LandingCalendly, useCalendly } from 'features/landing/LandingCalendly';
import LandingCookiesPopup from 'features/landing/LandingCookiesPopup';
import LandingFooter from 'features/landing/LandingFooter';
import LandingHeader from 'features/landing/LandingHeader';
import LandingSubscribeDialog from 'features/landing/LandingSubscribeDialog';

import amplitudeHelper from 'helpers/Amplitude';

export default function Landing(props) {
  // This all for track scroll position and send it to amplitude
  const [sentScroll, setSentScroll] = useState([]);
  const [checkScrollPositions, setScrollPositions] = useState([25, 50, 75, 95]);
  const { openCalendly, closeCalendly, scheduleEvent, displayCalendly } = useCalendly();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const percent = Math.round(
        ((document.documentElement.scrollTop + document.body.scrollTop) /
          (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
          100
      );
      checkScrollPositions.forEach((value) => {
        if (percent >= value && !sentScroll.includes(value)) {
          const newState = sentScroll;
          newState.push(value);
          setSentScroll(newState);
          sendScrollPosition(value);
        }
        setScrollPositions(checkScrollPositions.filter((e) => !sentScroll.includes(e)));
      });
    },
    [sentScroll],
    undefined,
    true,
    250
  );

  useEffect(() => {
    setSentScroll([]);
    setScrollPositions([25, 50, 75, 95]);
  }, [window.location.pathname]);

  const sendScrollPosition = (value) => {
    let page = '';
    switch (window.location.pathname) {
      case '/about':
        page = 'features';
        break;
      case '/blog':
        page = 'resources';
        break;
      case '/contact':
        page = 'contact';
        break;
      case '/pricing':
        page = 'pricing';
        break;
      default:
        page = 'main';
    }
    try {
      amplitudeHelper.logEvent('scroll', {
        percent_scrolled: `scroll_${value}`,
        page: page
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };
  // --------

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate('/dashboard');
    }
  }, [user]);

  const company = useSelector((state) => state.company);
  const userRole = user?.profile?.accessible_company_ids?.includes(company?.id)
    ? 'manager'
    : 'user';

  if (user.isLoggedIn) {
    const redirectRoute = userRole == 'manager' ? '/dashboard' : '/dashboard/gratitude/company';
    navigate(redirectRoute);
  }

  const childrenWithCalendly = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { openCalendly });
    }
    return child;
  });

  return (
    <>
      {displayCalendly ? (
        <LandingCalendly
          open={displayCalendly}
          onClose={closeCalendly}
          scheduleEvent={scheduleEvent}
        />
      ) : null}
      <Fade in={true} mountOnEnter unmountOnExit>
        <Box className={styles.landingWrapper}>
          <LandingCookiesPopup />
          {openDialog && (
            <LandingSubscribeDialog open={true} onClose={() => setOpenDialog(false)} />
          )}
          <BodyStyles contextName={'landing'} />
          <BodyBackground color='FFFFFF' />
          <LandingHeader onAction={() => navigate('/demo')} openCalendly={openCalendly} />
          {childrenWithCalendly}
          <LandingFooter />
        </Box>
      </Fade>
    </>
  );
}

Landing.propTypes = {
  children: PropTypes.element
};
