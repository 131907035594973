import { ONBOARDING_STEP_INDEX_CHANGE } from 'redux/actionTypes/onboarding';
import initialState from 'redux/initialState';

export default function onboarding(state = initialState.onboarding, action) {
  switch (action.type) {
    case ONBOARDING_STEP_INDEX_CHANGE:
      return {
        stepIndex: action.payload
      };
    default:
      return state;
  }
}
