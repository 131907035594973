import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function BodyBackground(props) {
  const location = useLocation();

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.background = `#${props.color}`;
    document.body.style.background = `#${props.color}`;
  }, [props.color, location.path]);

  return null;
}

BodyBackground.propTypes = {
  color: PropTypes.string
};
