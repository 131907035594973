/* Capterra Pixel*/

class CapterraPixel {
  pixel;

  constructor(vkey, vid) {
    this.pixel = document.createElement('img');
    this.pixel.src = this.createPixelUrl(vkey, vid);
  }

  createPixelUrl(vid, vkey) {
    return `https://ct.capterra.com/capterra_tracker.gif?vid=${vid}&vkey=${vkey}`;
  }

  trackConversion() {
    document.body.appendChild(this.pixel);
  }
}

const CAPTERRA_VKEY = '1c224b364c7e927ac2e4245cfe07ba7b';
const CAPTERRA_VID = '2228610';

export const capterraPixel = new CapterraPixel(CAPTERRA_VKEY, CAPTERRA_VID);

/* Quora Pixel*/

class QuoraPixel {
  accountId;

  constructor(accountId) {
    this.accountId = accountId;
    this.initializeQuoraPixel();
  }

  initializeQuoraPixel() {
    const pixelSrc = `https://q.quora.com/_/ad/${this.accountId}/pixel?tag=ViewContent`;
    this.insertPixel(pixelSrc);
  }

  trackEvent(eventName) {
    const pixelSrc = `https://q.quora.com/_/ad/${this.accountId}/pixel?tag=${eventName}`;
    this.insertPixel(pixelSrc);
  }

  insertPixel(src) {
    document.head.insertAdjacentHTML(
      'beforeend',
      `<!-- DO NOT MODIFY -->
      <!-- Quora Pixel Code (Static HTML) -->
      <img height="1" width="1" style="display:none" src="${src}"/>
      <!-- End of Quora Pixel Code -->`
    );
  }
}

const QUORA_ACCOUNT_ID = 'dc309b728a6f467ebb429960d57063a7';

export const quoraPixel = new QuoraPixel(QUORA_ACCOUNT_ID);
