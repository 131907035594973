import * as amplitude from '@amplitude/analytics-browser';

const API_KEY = '14e94783e09932e4fa69c3beceea6c0f';

class AmplitudeHelper {
  constructor() {
    this.commonParams = {};

    // if (localStorage.getItem('accept_cookies') === 'true') {
    amplitude.init(API_KEY, { defaultTracking: false });
    console.log('Amplitude: initialized.');
    // }
  }

  updateCommonParams(params) {
    this.commonParams = { ...this.commonParams, ...params };
    console.log(this.commonParams);
  }

  logEvent(eventName, params) {
    let mergedParams = { ...this.commonParams, ...params };
    if (process.env.NODE_ENV === 'development') {
      console.log(`Amplitude: event: ${eventName}, params: ${JSON.stringify(mergedParams)}.`);
    } else {
      try {
        // if (localStorage.getItem('accept_cookies') === 'true') {
        amplitude.track(eventName, mergedParams);
        console.log(`Amplitude: event: ${eventName}, params: ${JSON.stringify(mergedParams)}.`);
        // }
      } catch (error) {
        console.error(error);
      }
    }
  }

  logUserProperty(property, params) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Amplitude: userProperty: ${property}, params: ${params}.`);
    } else {
      // if (localStorage.getItem('accept_cookies') === 'true') {
      try {
        const identify = new amplitude.Identify().set(property, params);
        amplitude.identify(identify);
        console.log(`Amplitude: userProperty: ${property}, params: ${JSON.stringify(params)}.`);
      } catch (error) {
        console.error(error);
      }
      // }
    }
  }
}

let amplitudeHelper = new AmplitudeHelper();
export default amplitudeHelper;
