import { combineReducers } from 'redux';
import company from 'redux/reducers/company';
import onboarding from 'redux/reducers/onboarding';
import team from 'redux/reducers/team';
import toast from 'redux/reducers/toast';
import user from 'redux/reducers/user';

export default combineReducers({
  user,
  company,
  onboarding,
  team,
  toast
});
