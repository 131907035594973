import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { saveState } from 'redux/localStore';
import rootReducer from 'redux/reducers';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

const store = createStore(rootReducer, composedEnhancer);

store.subscribe(() => {
  saveState('user', store.getState().user);
  saveState('company', store.getState().company);
  saveState('onboarding', store.getState().onboarding);
  saveState('team', store.getState().team);
  saveState('toast', store.getState().toast);
});

export default store;
