import React from 'react';

import { Box, Container } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import imgFeature3 from 'static/landing/img_email.webp';
import imgFeature1 from 'static/landing/img_slack.webp';
import imgFeature2 from 'static/landing/img_teams.webp';
import imgSpecPhoto from 'static/landing/landing_spec_photo.webp';
import linkedinIcon from 'static/landing/linkedin_icon.svg';

export default function LandingSection3() {
  return (
    <Box className={styles.section3Wrapper}>
      <Container maxWidth='lg' className={styles.container1296}>
        <h2 className={styles.sectionTitle} style={{ margin: '0 auto', maxWidth: '87rem' }}>
          Developed in close collaboration with psychology experts and HR leaders
        </h2>
        <Box>
          <Box className={styles.section3SpecWrapper}>
            <Box className={styles.section3SpecColLeft}>
              <Box className={styles.section3SpecImageWrapper}>
                <img alt='spec photo' src={imgSpecPhoto} style={{ width: '43.8rem' }} />
              </Box>
              <Box>
                <span className={styles.hideSM}>
                  Jana Oetken, M. Sc. Human Resource Management and B. Sc. I/O Psychology
                </span>
                <span className={styles.showSM}>
                  Jana Oetken, M. Sc. Human
                  <br />
                  Resource Management
                  <br />
                  and B. Sc. I/O Psychology
                </span>
                <a
                  href='https://www.linkedin.com/in/janaoetken/'
                  target='_blank'
                  rel='noreferrer'
                  className={styles.section3SpecIconLinkedin}>
                  <img alt='linkedin icon' src={linkedinIcon} />
                </a>
              </Box>
            </Box>
            <Box className={styles.section3SpecColRight}>
              <div>
                “I was happy to apply my expertise to work on Beams as an advisor. My experience
                made me strongly believe that psychological comfort at work is a key to better team
                performance. Psychological safety is about valuing differences and individuals being
                able to be their authentic selves.
                <br />
                Another positive outcome of psychological safety would be learning behavior. That’s
                why it’s crucial to make everybody feel appreciated, heard, and engaged.
                <br />
                <strong>
                  Beams is a powerful tool to help managers and employees fuel their motivation and
                  overcome common problems such as miscommunication, low engagement, and a lack of
                  recognition. Take care of your team, and watch it prosper!”
                </strong>
              </div>
            </Box>
          </Box>
        </Box>
        <h2 className={`${styles.sectionTitle} ${styles.section3Title}`}>
          Designed for your existing workflow
        </h2>
        <Box className={styles.section3Subtitle}>
          Beams integrates seamlessly with your preferred communication tool to engage employees
          without distracting from their work
        </Box>
        <Box className={styles.section3Flex}>
          <Box className={styles.section3FeatureFlex}>
            <img alt='slack icon' src={imgFeature1} />
            Slack
          </Box>
          <Box className={styles.section3FeatureFlex}>
            <img alt='email icon' src={imgFeature3} />
            Email
          </Box>
          <Box className={styles.section3FeatureFlex}>
            <img alt='microsoft teams icon' src={imgFeature2} />
            Microsoft Teams
            <Box className={styles.section3ComingSoonLabel}>Coming soon</Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
