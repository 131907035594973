import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box } from '@mui/material';

import landingStyles from 'features/landing/Landing.module.scss';

import { SpinnerButton } from 'features/shared/Buttons';
import { PrimaryInput } from 'features/shared/PrimaryInput';

import amplitudeHelper from 'helpers/Amplitude';
import ValidateEmail from 'helpers/ValidateEmail';

import iconFinish from 'static/landing/l_dialog_finish.webp';

export default function LandingSubscribeDialog(props) {
  const [email, setEmail] = useState('');
  const [validationError, setValidationError] = useState();
  const [displayFinish, setDisplayFinish] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setValidationError(null);

    if (email.length === 0 || ValidateEmail(email) === false) {
      setValidationError('Please enter a valid email');
      return;
    }

    sendData();
  };

  const sendData = () => {
    setLoading(true);
    // window.gtag('event', 'demo_request_sent');
    try {
      amplitudeHelper.logEvent('request_access_clicked', {
        email: email
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }

    const dataToPost = new FormData();
    dataToPost.append('entry.582852591', email);

    fetch(
      'https://docs.google.com/forms/u/0/d/e/1FAIpQLScIYVc15h6aKg6ELvzgprmAtTtiWww7BBeCb77X4-1d3pRsew/formResponse',
      {
        method: 'POST',
        mode: 'no-cors',
        header: { 'Content-Type': 'application/json' },
        body: dataToPost
      }
    ).then((response) => {
      setDisplayFinish(true);
      setLoading(false);

      let subscribersCount = localStorage.getItem('landing_subscribers') || 1123;
      subscribersCount = Number(subscribersCount) + 1;
      localStorage.setItem('landing_subscribers', subscribersCount);
    });
  };

  return (
    <Backdrop open={props.open} className={landingStyles.backdrop}>
      <Box className={landingStyles.card}>
        <link rel='preload' as='image' href={iconFinish} />
        <Box
          style={{
            position: 'absolute',
            top: 20,
            left: 20
          }}
          // onClick={() => props.onClose(false)}
        >
          <CloseIcon style={{ cursor: 'pointer', fontSize: 30 }} onClick={props.onClose} />
        </Box>
        {!displayFinish && (
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box className={landingStyles.cardTitle}>Make your team engaged!</Box>
            <Box className={landingStyles.cardSubtitle} style={{ marginTop: 5 }}>
              Be the first to know when we launch.
            </Box>
            <Box className={landingStyles.cardBody} style={{ marginTop: 40 }}>
              Join <strong>Zack</strong>, <strong>Eve</strong>, and{' '}
              <strong>{localStorage.getItem('landing_subscribers') || 1123}</strong> others on the
              waitlist.
            </Box>
            <PrimaryInput
              placeholder='Enter your email'
              style={{ marginTop: 10 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              validationError={validationError}
            />
            <SpinnerButton
              style={{ marginTop: 110, fontWeight: 400 }}
              onClick={handleButtonClick}
              disabled={loading}>
              Request Access
            </SpinnerButton>
          </Box>
        )}
        {displayFinish && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: 100
            }}>
            <Box>
              <img alt='handshake icon' width='128' height='158' src={iconFinish} />
            </Box>
            <Box className={landingStyles.cardFinishTitle} style={{ marginTop: 30 }}>
              Thank you!
            </Box>
            <Box className={landingStyles.cardFinishTitle}>Beams are coming soon.</Box>
            <Box className={landingStyles.cardFinishTitle}>
              We&apos;ll notify about the launch date.
            </Box>
          </Box>
        )}
      </Box>
    </Backdrop>
  );
}

LandingSubscribeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
