import axios from 'axios';
import { changeCurrentCompanyId } from 'redux/actions/company';
import {
  CONFIRM_USER_STARTED,
  CONFIRM_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_STARTED,
  GET_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  SET_USER_EMAIL,
  SET_USER_NAME,
  STORE_USER_TOKEN,
  UPDATE_USER_FAILURE,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS
  // RESEND_USER_CONFIRMATION_CODE_SUCCESS,
} from 'redux/actionTypes/user';
import store from 'redux/store';

import PrepareErrors from 'helpers/PrepareErrors';

export const userState = (state) => state.user;

const getUserStarted = () => ({
  type: GET_USER_STARTED
});

const getUserSuccess = (profile) => {
  return {
    type: GET_USER_SUCCESS,
    payload: {
      ...profile
    }
  };
};

const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  payload: {
    ...error
  }
});

const createUserSuccess = (profile) => ({
  type: CREATE_USER_SUCCESS,
  payload: {
    ...profile
  }
});

const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: {
    email
  }
});

const setUserName = (name) => ({
  type: SET_USER_NAME,
  payload: {
    name
  }
});

const storeTokenToUser = (token) => ({
  type: STORE_USER_TOKEN,
  payload: {
    token
  }
});

const refreshLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('skipPulseIntroScreen');
  localStorage.removeItem('company');
  localStorage.removeItem('user');
  localStorage.removeItem('onboarding');
  localStorage.removeItem('team');
};

const refreshUserStore = {
  type: LOGOUT_USER_SUCCESS,
  payload: {
    loading: false,
    error: null,
    isLoggedIn: false,
    isRegistered: false,
    profile: {
      companies: []
    },
    isTokenExpired: true,
    token: null
  }
};

const createUser = (data) => async (dispatch) => {
  dispatch(getUserStarted());

  fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((json) => {
          throw json;
        });
      }
    })
    .then((json) => {
      dispatch(
        createUserSuccess({
          password: data.user.password,
          ...JSON.parse(json.data)
        })
      );
    })
    .catch((error) => {
      let isError = Object.keys(error).includes('message');
      let payload = isError
        ? {
            error: error.message
          }
        : error;

      dispatch(getUserFailure(PrepareErrors(payload)));
    });
};

const confirmUser = (confirmationToken) => (dispatch) => {
  dispatch({
    type: CONFIRM_USER_STARTED
  });

  fetch(
    `${process.env.REACT_APP_BACKEND_URL}/users/confirmation?confirmation_token=${confirmationToken}`,
    {
      method: 'GET'
    }
  )
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: CONFIRM_USER_SUCCESS
        });

        let user = store.getState().user.profile;

        dispatch(
          signInUser({
            user: {
              email: user.email,
              password: user.password
            }
          })
        );
      } else {
        dispatch(
          getUserFailure(
            PrepareErrors({
              error: 'Incorrect confirmation code'
            })
          )
        );
      }
    })
    .catch((error) => {
      dispatch(
        getUserFailure(
          PrepareErrors({
            error: error.message
          })
        )
      );
    });
};

const signInUser = (data) => (dispatch) => {
  dispatch(getUserStarted);

  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/sign_in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (response.ok) {
        dispatch(storeTokenToUser(response.headers.get('Authorization')));
        return response.json();
      } else {
        return response.json().then((json) => {
          throw json;
        });
      }
    })
    .then((json) => {
      dispatch(getUserSuccess(json.data));
    })
    .catch((error) => {
      let isError = Object.keys(error).includes('message');
      let payload = isError
        ? {
            error: error.message
          }
        : error;

      dispatch(getUserFailure(PrepareErrors(payload)));
    });
};

const sendConfirmationCode = (data) => async (dispatch) => {
  dispatch(getUserStarted());

  fetch(`${process.env.REACT_APP_BACKEND_URL}/users/confirmation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((json) => {
          throw json;
        });
      }
    })
    .then(() => {
      dispatch({
        type: 'RESEND_USER_CONFIRMATION_CODE_SUCCESS'
      });
    })
    .catch((error) => {
      let isError = Object.keys(error).includes('message');
      let payload = isError
        ? {
            error: error.message
          }
        : error;

      dispatch(getUserFailure(PrepareErrors(payload)));
    });
};

const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_STARTED
  });
  const token = localStorage.getItem('token');

  axios({
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/users`,
    data: data,
    headers: {
      Authorization: token
    }
  })
    .then((resp) => {
      switch (resp.status) {
        case 200:
          return dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: resp.data
          });

        case 401:
          return dispatch({
            type: UPDATE_USER_FAILURE,
            payload: resp.data.error
          });
      }
    })
    .catch((error) => {
      switch (error.response.status) {
        case 422: {
          let isError = Object.keys(error.response.data.errors).includes('message');
          let payload = isError
            ? {
                error: error.response.data.errors.message
              }
            : error.response.data.errors;

          return dispatch(getUserFailure(PrepareErrors(payload)));
        }
      }
    });
};

const fetchLastCompanyId = (profile) => {
  return profile?.companies?.pop()?.id;
};

const loginUser = (data) => async (dispatch) => {
  dispatch(getUserStarted());
  let cleanAxios = axios.create();
  cleanAxios
    .post(`${process.env.REACT_APP_BACKEND_URL}/users/sign_in`, data)
    .then((resp) => {
      switch (resp.status) {
        case 200:
          dispatch(storeTokenToUser(resp.headers.authorization));
          // dispatch(changeCurrentCompanyId(fetchLastCompanyId(resp.data.data)));
          return dispatch(getUserSuccess(resp.data.data));

        case 401:
          return dispatch({
            type: GET_USER_FAILURE,
            payload: resp.json.error
          });
      }
    })
    .catch((error) => {
      let isError = Object.keys(error).includes('message');
      let payload = isError
        ? {
            error: error.message
          }
        : error;

      switch (error.response?.status) {
        case 401:
          payload = {
            unauthorized: ['Enter a valid email address and password.']
          };
          dispatch(getUserFailure(PrepareErrors(payload)));
          return;
        default: {
          return dispatch(getUserFailure(PrepareErrors(payload)));
        }
      }
    });
};

const getCurrentUserSuccess = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/current_user`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token')
      }
    })
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(getUserSuccess(resp.data));
      } else {
        refreshLocalStorage();
        dispatch(refreshUserStore);
      }
    })
    .catch((error) => {
      let isError = Object.keys(error).includes('message');
      let payload = isError ? { error: error.message } : error;

      refreshLocalStorage();
      dispatch(refreshUserStore);
      // dispatch(getUserFailure(PrepareErrors(payload)));
    });
};

const logoutUser = () => (dispatch) => {
  axios
    .delete(`${process.env.REACT_APP_BACKEND_URL}/users/sign_out`)
    .then((resp) => {
      switch (resp.status) {
        case 200:
          refreshLocalStorage();
          dispatch(refreshUserStore);
          return;
        case 401:
          return dispatch({
            type: GET_USER_FAILURE,
            payload: resp.json.error
          });
      }
    })
    .catch((error) => {
      let isError = Object.keys(error).includes('message');
      let payload = isError
        ? {
            error: error.message
          }
        : error;

      switch (error.response?.status) {
        case 401:
          // payload = {
          //   unauthorized: ['Enter a valid email address and password'],
          // };
          // dispatch(getUserFailure(PrepareErrors(payload)));
          return;
        default: {
          return dispatch(getUserFailure(PrepareErrors(payload)));
        }
      }
    });
};

export {
  createUser,
  createUserSuccess,
  confirmUser,
  setUserEmail,
  setUserName,
  sendConfirmationCode,
  signInUser,
  updateUser,
  loginUser,
  getUserSuccess,
  getCurrentUserSuccess,
  storeTokenToUser,
  logoutUser
};
