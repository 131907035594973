export const noindexBlogPages = [
  '/what-is-supplemental-pay',
  '/chief-people-officer',
  '/360-feedback-examples',
  '/types-of-employees',
  '/feedback-fridays',
  '/employee-voice',
  '/employee-probation-review',
  '/employee-sentiment',
  '/how-to-measure-culture',
  '/quotes-on-employee-engagement',
  '/virtual-employee-engagement-activities',
  '/employee-engagement-apps',
  '/employee-engagement-survey-questions',
  '/employee-engagement-model',
  '/employee-engagement-ideas-2023',
  '/employee-recognition',
  '/mid-year-performance-review',
  '/how-to-deal-with-burnout',
  '/one-on-one-meeting-templates',
  '/teamwork-makes-the-dreamwork',
  '/positive-feedback-examples'
];
