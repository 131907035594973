export const GET_USER_STARTED = 'GET_USER_STARTED';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';

export const UPDATE_USER_STARTED = 'UPDATE_USER_STARTED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const CONFIRM_USER_STARTED = 'CONFIRM_USER_STARTED';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_NAME = 'SET_USER_NAME';

export const STORE_USER_TOKEN = 'STORE_USER_TOKEN';

export const RESEND_USER_CONFIRMATION_CODE_SUCCESS = 'RESEND_USER_CONFIRMATION_CODE_SUCCESS';
