import jwtDecode from 'jwt-decode';
import { loadState } from 'redux/localStore';

const token = localStorage.getItem('token');
let tokenExpired = true;
if (token && ['null', 'undefined', null, undefined].includes(token) === false) {
  var decoded_token = jwtDecode(token.replace('Bearer ', ''));
  tokenExpired = decoded_token.exp * 1000 < Date.now();
}

const savedUser = JSON.parse(localStorage.getItem('user') || '{}');

const userState = {
  loading: false,
  error: null,
  isRegistered: false,
  isLoggedIn: false,
  isTokenExpired: tokenExpired,
  token: tokenExpired ? null : token,
  profile: {
    id: '',
    role: '',
    name: '',
    email: '',
    isConfirmed: false,
    isValid: false,
    skip_pulse_intro_screen: savedUser.profile?.skip_pulse_intro_screen || false
  }
};

const companyState = loadState('company') || {
  loading: false,
  error: null,
  title: null,
  id: null
};

const onboardingState = loadState('onboarding') || {
  stepIndex: 0
};

const teamState = loadState('team') || {
  loading: false,
  error: null,
  teams: null
};

const toastState = {
  notifications: []
};

const initialState = {
  user: userState,
  company: companyState,
  onboarding: onboardingState,
  team: teamState,
  toast: toastState
};

export default initialState;
