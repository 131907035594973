import {
  BULK_CREATE_TEAMS_FAILURE,
  BULK_CREATE_TEAMS_STARTED,
  BULK_CREATE_TEAMS_SUCCESS
} from 'redux/actionTypes/team';
import initialState from 'redux/initialState';

export default function team(state = initialState.team, action) {
  switch (action.type) {
    case BULK_CREATE_TEAMS_STARTED:
      return {
        ...state,
        loading: true
      };
    case BULK_CREATE_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        teams: {
          ...action.payload
        }
      };
    case BULK_CREATE_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
