import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, FormControl, Input, MenuItem, Select, Stack } from '@mui/material';

import styles from 'features/landing/LandingDemoPage.module.scss';

import BodyBackground from 'features/shared/BodyBackground';
import BodyStyles from 'features/shared/BodyStyles';
import { ButtonLg } from 'features/shared/Buttons';
import CanonicalLink from 'features/shared/CanonicalLink';
import MetaTags from 'features/shared/MetaTags';

import amplitudeHelper from 'helpers/Amplitude';
import { capterraPixel } from 'helpers/ConversionTrackers';
import ValidateEmail from 'helpers/ValidateEmail';

import reviewPhoto from 'static/landing/demo_photo_review.webp';
import imgCheckmark from 'static/landing/i_demo_checkmark.svg';
import imgDemo1 from 'static/landing/img_demo_1.svg';
import imgDemo2 from 'static/landing/img_demo_2.svg';
import imgDemo3 from 'static/landing/img_demo_3.svg';
import imgDemo4 from 'static/landing/img_demo_4.svg';
import imgLogo from 'static/landing/logo.svg';

import LandingTrustBadges from './LandingTrustBadges';

const COMPANY_SIZE_OPTIONS = ['2-10', '11-50', '51-200', '201-500', '501-1000', '1001+'];

const ROLE_OPTIONS = ['Founder', 'C-level', 'Human Resource Specialist', 'Manager', 'Other'];

function isCalendlyEvent(e) {
  return (
    e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0
  );
}

export const sendData = (object) => {
  const dataToPost = new FormData();
  dataToPost.append('entry.764118273', object.firstName);
  dataToPost.append('entry.938348563', object.lastName);
  dataToPost.append('entry.1968284649', object.email);
  dataToPost.append('entry.182081258', object.role);
  dataToPost.append('entry.1283739390', object.company);
  dataToPost.append('entry.754607022', object.utmCampaign);
  dataToPost.append('entry.608329253', object.utmMedium);
  dataToPost.append('entry.1048260180', object.utmSource);

  fetch(
    'https://docs.google.com/forms/d/e/1FAIpQLSeQDBG-vkPOWOgw46TVNfAGE9gduo9F8756OI8r_H9IdvSqNg/formResponse',
    {
      method: 'POST',
      mode: 'no-cors',
      header: { 'Content-Type': 'application/json' },
      body: dataToPost
    }
  ).then((response) => {
    console.log('form submitted');
  });
};

export default function LandingDemoPage() {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    companySize: '',
    role: ''
  });
  const navigate = useNavigate();
  const [isWidgetInitied, setIsWidgetInitied] = useState(false);
  const [displayBackdrop, setDisplayBackdrop] = useState(false);
  const [eventScheduled, setEventScheduled] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [validationError, setValidationError] = useState({
    firstName: null,
    lastName: null,
    workEmail: null
  });

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const handleButtonClick = () => {
    setValidationError({
      firstName: null,
      lastName: null,
      workEmail: null
    });

    let emailValidationError =
      ValidateEmail(state.workEmail) === false ? 'Enter a valid email.' : null;
    if (state.workEmail.length === 0) {
      emailValidationError = 'Enter your email.';
    }
    let newValue = {
      firstName: state.firstName.length === 0 ? 'Enter your first name.' : null,
      lastName: state.lastName.length === 0 ? 'Enter your last name.' : null,
      workEmail: emailValidationError
    };

    setValidationError(newValue);

    if (Object.values(newValue).filter((v) => v !== null).length > 0) {
      return;
    }

    window.gtag('event', 'demo_form_submitted');

    amplitudeHelper.logEvent('demo_form_submitted', {
      first_name: state.firstName,
      last_name: state.lastName,
      email: state.workEmail,
      role: state.role,
      company_size: state.companySize
    });

    sendData({
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.workEmail,
      role: state.role,
      company: state.companySize,
      utmCampaign: amplitudeHelper.commonParams?.utm_campaign || '—',
      utmMedium: amplitudeHelper.commonParams?.utm_medium || '—',
      utmSource: amplitudeHelper.commonParams?.utm_source || '—'
    });

    amplitudeHelper.logEvent('demo_calendly_opened', {});
    setDisplayBackdrop(true);
    if (isWidgetInitied === false) {
      // eslint-disable-next-line
      Calendly.initInlineWidget({
        //eslint-disable-line
        url: 'https://calendly.com/beams-team',
        parentElement: document.getElementById('js-calendly-backdrop'),
        prefill: {
          name: state.firstName + ' ' + state.lastName,
          email: state.workEmail,
          customAnswers: {
            a1: state.companySize,
            a2: state.role
          }
        }
      });
      setIsWidgetInitied(true);
    }

    window.addEventListener('message', function (e) {
      if (isCalendlyEvent(e)) {
        /* Example to get the name of the event */
        console.log('Event name:', e.data.event);

        /* Example to get the payload of the event */
        console.log('Event details:', e.data.payload);

        if (e.data.event === 'calendly.date_and_time_selected') {
          capterraPixel.trackConversion();
          console.log('gtag - demo_time_selected');
          window.gtag('event', 'demo_time_selected');
        }

        if (e.data.event === 'calendly.event_scheduled') {
          localStorage.setItem('calendlyEventScheduled', 'true');
          amplitudeHelper.logEvent('demo_event_scheduled', {});
          console.log('gtag - demo_scheduled');
          window.gtag('event', 'demo_scheduled');
          setEventScheduled(true);
        }
      }
    });
  };

  const handleBackdropCloseButtonClick = () => {
    setDisplayBackdrop(false);
    if (eventScheduled === true) {
      amplitudeHelper.logEvent('demo_close_button_clicked', { after_event: 'true' });
      navigate('/thank-you');
    } else {
      amplitudeHelper.logEvent('demo_close_button_clicked', { after_event: 'false' });
    }
  };

  return (
    <>
      <CanonicalLink link={`${process.env.REACT_APP_BASE_URL}/demo`} />
      <MetaTags
        title='Book a Demo | Beams.ai: Explore Employee Engagement Tools'
        description='Request a demo at Beams.ai. Explore our employee engagement tools firsthand and transform your workplace dynamics.'
      />
      <Box>
        <Box>
          <Backdrop
            className={styles.backdrop}
            open={displayBackdrop}
            id='js-calendly-backdrop'
            style={{ zIndex: 2000 }}>
            <Box
              style={{ position: 'absolute', top: 10, right: 10 }}
              onClick={handleBackdropCloseButtonClick}>
              <CloseIcon style={{ cursor: 'pointer', fontSize: 40, color: '#FFF' }} />
            </Box>
          </Backdrop>
          <BodyStyles contextName={'landing'} />
          <BodyBackground color='FFFFFF' />
          <Box className={styles.header}>
            <Box className={styles.headerContainer}>
              <Link to='/'>
                <img src={imgLogo} alt='logo' />
              </Link>
              <Box></Box>
            </Box>
          </Box>
          <Box className={styles.section}>
            <Box className={styles.sectionColumn1}>
              <Box component='h1' className={styles.sectionTitle}>
                You Are One Click Away from Enhancing Your Team&apos;s Performance and Happiness
              </Box>
              <Box className={styles.sectionSubtitle}>
                Simply fill out the form and select a convenient time for a quick 20-minute call.
              </Box>
              <Stack className={styles.sectionBadgesContainer}>
                <LandingTrustBadges />
              </Stack>
              <Stack className={styles.review}>
                <Stack className={styles.reviewPhoto}>
                  <img src={reviewPhoto} />
                </Stack>
                <Stack className={styles.reviewContent}>
                  <Box className={styles.reviewText}>
                    “Beams is a powerful tool to help managers and employees fuel their motivation
                    and overcome common problems such as miscommunication, low engagement, and a
                    lack of recognition.“
                  </Box>
                  <Box className={styles.reviewAuthor}>
                    Jana Oetken, M. Sc. Human Resource Management and B. Sc. I/O Psychology
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Box className={styles.sectionColumn2}>
              <Box className={styles.formWrapper}>
                <img src={imgDemo1} alt='demo' className={styles.formImage1} />
                <img src={imgDemo2} alt='demo' className={styles.formImage2} />
                <img src={imgDemo3} alt='demo' className={styles.formImage3} />
                <img src={imgDemo4} alt='demo' className={styles.formImage4} />
                <Box className={styles.formTitle}>Schedule a demo</Box>
                <Box className={styles.formSubtitle}>
                  Our friendly experts are happy to answer your questions or set up a free 14-day
                  trial for you.
                </Box>
                <Box className={styles.formContent}>
                  <Input
                    disableUnderline
                    className={`${styles.input} ${validationError.firstName && styles.inputError}`}
                    placeholder='First Name*'
                    onChange={(e) => setState({ ...state, firstName: e.target.value })}
                  />
                  {/* {validationError.firstName && <Box className={styles.inputError}>{validationError.firstName}</Box>} */}
                  <Input
                    disableUnderline
                    className={`${styles.input} ${validationError.lastName && styles.inputError} ${
                      styles.marginTop
                    }`}
                    placeholder='Last Name*'
                    onChange={(e) => setState({ ...state, lastName: e.target.value })}
                  />
                  {/* {validationError.lastName && <Box className={styles.inputError}>{validationError.lastName}</Box>} */}
                  <Input
                    disableUnderline
                    className={`${styles.input} ${validationError.workEmail && styles.inputError} ${
                      styles.marginTop
                    }`}
                    placeholder='Work Email*'
                    onChange={(e) => setState({ ...state, workEmail: e.target.value })}
                  />
                  <Input
                    disableUnderline
                    className={`${styles.input} ${styles.marginTop}`}
                    placeholder='Your Role'
                    onChange={(e) => setState({ ...state, role: e.target.value })}
                  />
                  {/* {validationError.workEmail && <Box className={styles.inputError}>{validationError.workEmail}</Box>} */}
                  <FormControl
                    fullWidth
                    // error={error?.errors?.user?.display_role ? true : false}
                    className={styles.marginTop}>
                    <Select
                      value={state.companySize}
                      displayEmpty
                      // value={displayRole}
                      onChange={(e) => setState({ ...state, companySize: e.target.value })}
                      // input={<OutlinedInput />}
                      // renderValue={(selected) => renderSelected(selected)}
                      inputProps={{ 'aria-label': 'Without label' }}
                      className={`${styles.select} no-image`}>
                      <MenuItem className={styles.menuItem} disabled value=''>
                        Company Size
                      </MenuItem>
                      {COMPANY_SIZE_OPTIONS.map((option, index) => {
                        return (
                          <MenuItem
                            className={`${styles.menuItem} ${
                              state.companySize === option && styles.menuItemSelected
                            }`}
                            key={index}
                            value={option}>
                            {option}
                            {state.companySize === option && (
                              <img
                                src={imgCheckmark}
                                alt='checkmark'
                                className={styles.checkmark}
                              />
                            )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormControl
                    fullWidth
                    // error={error?.errors?.user?.display_role ? true : false}
                    className={styles.marginTop}
                    // className={`${styles.select} no-image`}
                  >
                    {/* <Select
                      value={state.role}
                      displayEmpty
                      // value={displayRole}
                      onChange={(e) => setState({ ...state, role: e.target.value })}
                      // input={<OutlinedInput />}
                      // renderValue={(selected) => renderSelected(selected)}
                      inputProps={{ 'aria-label': 'Without label' }}
                      className={`${styles.select} no-image`}
                    > */}
                  {/* <Box className={styles.menuWrapper}>
                      <MenuItem className={styles.menuItem} disabled value=''>
                        Your Role
                      </MenuItem>
                      {ROLE_OPTIONS.map((option, index) => {
                        return (
                          <MenuItem
                            className={`${styles.menuItem} ${state.role === option && styles.menuItemSelected}`}
                            key={index}
                            value={option}
                            onClick={() => setState({ ...state, role: option })}
                          >
                            {option}
                            {state.role === option && <img src={imgCheckmark} alt='checkmark' className={styles.checkmark} />}
                          </MenuItem>
                        )
                      })}
                    </Box> */}
                  {/* </Select> */}
                  {/* </FormControl> */}
                </Box>
                <Box className={styles.formButton}>
                  <ButtonLg className={styles.button} onClick={handleButtonClick}>
                    Request Demo
                  </ButtonLg>
                </Box>
              </Box>
              <Box className={styles.formDisclaimer}>
                By submitting this form, you give consent to receive further communications from
                Beams. Unsubscribe at any time.
                <br />
                No credit card is needed.
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
