import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import amplitudeHelper from 'helpers/Amplitude';

import iconFacebook from 'static/landing/i_landing_facebook.svg';
import iconLinkedIn from 'static/landing/i_landing_linkedin.svg';
import iconTwitter from 'static/landing/i_landing_twitter.svg';
import imgLogo from 'static/landing/logo.svg';

export default function LandingFooter() {
  const whyBeamsClickHandler = () => {
    try {
      amplitudeHelper.logEvent('why_beams_page_opened', {
        source: 'footer'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  const pricingClickHandler = () => {
    try {
      amplitudeHelper.logEvent('pricing_page_opened', {
        source: 'footer'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  const resourceClickHandler = () => {
    try {
      amplitudeHelper.logEvent('resources_page_opened', {
        source: 'footer'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  const contactClickHandler = () => {
    try {
      amplitudeHelper.logEvent('contact_page_opened', {
        source: 'footer'
      });
    } catch (e) {
      console.log('amplitudeHelper ERROR: ', e);
    }
  };

  return (
    <Box className={styles.footerWrapper}>
      <Container maxWidth='lg' style={{ maxWidth: '129.6rem' }}>
        <Box className={styles.footerFlex}>
          <Box className={styles.rem21}>
            <img alt='beams logo' style={{ width: '100%', maxWidth: '13rem' }} src={imgLogo} />
          </Box>
          <Box className={styles.footerMenu}>
            <NavLink
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/about'
              end
              onClick={whyBeamsClickHandler}
              style={{ marginLeft: 'auto' }}>
              Features
            </NavLink>
            <NavLink
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/pricing'
              end
              onClick={pricingClickHandler}
              style={{ marginLeft: 'auto' }}>
              Pricing
            </NavLink>
            <NavLink
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/blog'
              end
              onClick={resourceClickHandler}>
              Resources
            </NavLink>
            <NavLink
              className={(state) => {
                return state.isActive ? styles.activeLink : '';
              }}
              to='/contact'
              end
              onClick={contactClickHandler}>
              Contact Us
            </NavLink>
          </Box>
          <Box className={styles.footerLinksFlex}>
            <a target='_blank' rel='noreferrer' href='https://www.facebook.com/getbeams'>
              <img src={iconFacebook} />
            </a>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/getbeams/'>
              <img src={iconLinkedIn} />
            </a>
            <a target='_blank' rel='noreferrer' href='https://twitter.com/getbeams'>
              <img src={iconTwitter} />
            </a>
          </Box>
        </Box>
        <Box className={styles.footerDivider} />
        <Box className={styles.footerMutedText}>
          <Box>© Copyright {new Date().getFullYear()}, All Rights Reserved</Box>
          <Box>
            <Link to='/privacy-policy' target='_blank'>
              Privacy Policy
            </Link>
            <Link to='/terms-of-service' target='_blank'>
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
