import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';

import CanonicalLink from 'features/shared/CanonicalLink';
import MetaTags from 'features/shared/MetaTags';
import ScrollToTopOnMount from 'features/shared/ScrollToTopOnMount';
import LandingSubscribeDialog from 'features/landing/LandingSubscribeDialog';
import LandingSection1 from 'features/landing/pages/main/LandingSection1';
import LandingSection2 from 'features/landing/pages/main/LandingSection2';
import LandingSection3 from 'features/landing/pages/main/LandingSection3';
import LandingSection4 from 'features/landing/pages/main/LandingSection4';
import LandingSection5 from 'features/landing/pages/main/LandingSection5';

import amplitudeHelper from 'helpers/Amplitude';

function LandingMainPage(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    amplitudeHelper.logEvent('landing_main_page_opened');
  }, []);

  return (
    <Box>
      <CanonicalLink link={`${process.env.REACT_APP_BASE_URL}/`} />
      <MetaTags
        title='Employee Engagement & Recognition Platform | Beams'
        description='Increase employee engagement with regular surveys delivered to your Slack or email. Develop an atmosphere of gratitude and feedback culture. Book a free demo!'
      />
      <ScrollToTopOnMount />
      {openDialog && <LandingSubscribeDialog open={true} onClose={() => setOpenDialog(false)} />}

      <LandingSection1 onOpenCalendly={props.openCalendly} onAction={() => navigate('/demo')} />
      <LandingSection2 />
      <LandingSection3 />
      <LandingSection4 />
      <LandingSection5 onOpenCalendly={props.openCalendly} onAction={() => navigate('/demo')} />
    </Box>
  );
}

LandingMainPage.propTypes = {
  openCalendly: PropTypes.func
};

const MemoizedLandingMainPage = memo(LandingMainPage);

export default MemoizedLandingMainPage;
