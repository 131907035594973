import React from 'react';

import styles from 'features/landing/LandingText.module.scss';

export default function LandingTermsOfUsePage() {
  return (
    <div className={styles.wrapper}>
      <h1>Terms of Service</h1>
      <p>Before using the Service, carefully read these Terms of Service (&quot;TOS&quot;).</p>
      <h2>1. In accordance with the Terms of Service</h2>
      <p>
        1.1 You agree to these Beams Terms of Service (&quot;TOS&quot;) by subscribing to Our
        Services at www.beams.ai (the &quot;Service&quot;).
      </p>
      <p>
        1.2 The Service can be accessed by either an individual or an (i) organization (such as a
        company). If you sign up for the Service on behalf of an organization, you guarantee I that
        you have the proper authority to speak for the organization and (ii) that you have the
        authority to accept the TOS on its behalf.
      </p>
      <p>
        1.3 We reserve the right to update or change the TOS at any time, and all subscription
        renewals are subject to the TOS in force at the time of renewal.
      </p>
      <h2>2. Rights, License, and Restrictions for Software Usage</h2>
      <p>
        2.1 We offer You a limited, non-exclusive, non-transferable permission to access and use Our
        hosted and proprietary software products and the related documentation (collectively
        referred to as the &quot;Software&quot;) via a web browser, subject to these TOS and the
        Term specified below. You may only use the Service for your own internal business needs.
      </p>
      <p>
        2.2. The Service is hosted by Beams.ai and made accessible to You via the internet and a web
        browser. Other than the rights expressly listed below, nothing in these TOS shall grant You
        any rights in or to the Service. Additionally, nothing in these TOS obligates Us to provide
        or deliver any copies of software or code (either in object code form or source code form)
        from the Software to You.
      </p>
      <p>
        2.3 You are prohibited from (i) copying, reproducing, altering, modifying, or creating
        derivative works from the Service; (ii) renting, leasing, disseminating, or selling the
        Service; (iii) using the Service as the basis for creating a rival product (or hiring a
        third party to do so); and (iv) removing or altering any logos, trademark, patent, or
        copyright notices, confidentiality or proprietary legends, or other notices or markings You
        agree to abide by all applicable local, state, national, and international laws, rules, and
        regulations when using the Service.
      </p>
      <p>
        2.4 You agree (i) not to use the Service in any way that is unlawful, libelous, abusive,
        harassing, fraudulent, obscene, contains viruses, or is otherwise objectionable as
        reasonably determined by Us. You also agree not to authorize or encourage any third party
        to: I do so; (ii) resell, duplicate, reproduce, or otherwise exploit any part of the Service
        without Our express written permission; or (iii) use any robot, spider, another automated
        device, or manual process.
      </p>
      <p>
        2.5 You are granted a premium license that comes with free basic support. Basic support
        includes making commercially reasonable efforts to keep the Service accessible 24 hours a
        day, seven days a week, with the exception of: (a) planned downtime, which we try to
        schedule outside of regular business hours; or (b) any unavailability brought on by events
        outside of our reasonable control, such as, but not limited to, acts of God, acts of
        government, floods, fires, earthquakes, civil unrest, acts of terror, strikes or other labor
        issues (other than those involving employees of Beams.ai), outages, delays, or
        denial-of-service attacks on Internet service providers.
      </p>
      <h2>3. Data Security</h2>
      <p>
        3.1 Our top goal is keeping Your data secure. The Privacy Policy outlines how we will
        protect your data and how it will be handled.
      </p>
      <p>
        3.2 We are required to abide by EU data protection law and only operate in compliance with
        it.
      </p>
      <p>
        3.3 Should We find that the security of Your data has been compromised, We will swiftly
        inform You of the type and extent of the breach and will start taking corrective action
        right away in accordance with industry norms.
      </p>
      <p>
        3.4 In addition to the liability clauses in these TOS, we will only be responsible for
        processing-related harm in cases where we have violated GDPR requirements that are unique to
        processors or when we have acted in bad faith.
      </p>
      <p>4. WARRANTIES AND DISCLAIMERS OF BEAMS</p>
      <p>
        4.1. The warranty. As long as the customer uses the Beams Platform properly, Beams
        represents and warrants that I the Beams Platform does not violate any third party
        intellectual property rights or constitute a misuse or misappropriation of a trade secret;
        (ii) the customer&apos;s use of the Beams Platform does not violate any third party
        intellectual property rights; and (iii) Beams has the legal authority to grant the customer
        access to and use of the Beams Platform.
      </p>
      <p>
        4.2. Disclaimers of Warranties. &nbsp;BEAMS DOES NOT WARRANT THAT USE OF THE WEBSITE OR THE
        BEAMS PLATFORM WILL BE SECURE, TIMELY, UNINTERRUPTED, ERROR-FREE, VIRUS-FREE, OR FREE OF
        OTHER HARMFUL COMPONENTS OR SUBJECT TO UNAUTHORIZED USE OR DISCLOSURE. THE CUSTOMER
        ACKNOWLEDGES THAT THE WEBSITE AND THE BEAMS PLATFORM ARE OFFERED &quot;AS IS&quot; AND
        &quot;AS AVAILABLE,&quot; WITH ANY AND ALL FAULTS AND ERRORS THAT MAY EXIST.
      </p>
      <p>
        4.3. BEAMS MAKES NO IMPLIED OR EXPRESS REPRESENTATIONS OR WARRANTIES, INCLUDING ANY
        REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. A
        THIRD PARTY&apos;S INFORMATION CREATED AND ACCESSIBLE ON THE WEBSITE OR THE BEAMS PLATFORM
        IS NOT ENDORSED BY BEAMS AND MAY NOT HAVE BEEN REVIEWED BY BEAMS. IT IS STILL THE THIRD
        PARTY&apos;S RESPONSIBILITY. THE ACCURACY, INTEGRITY, OR QUALITY OF SUCH CUSTOMER DATA, AS
        WELL AS OTHER INFORMATION OBTAINED THROUGH THE WEBSITE OR THE BEAMS PLATFORM, ARE NOT
        GUARANTEED BY BEAMS, WHICH DOES NOT CONTROL CUSTOMER DATA. ONLY THE CUSTOMER IS RESPONSIBLE
        FOR EVALUATING THE ACCURACY, RELIABILITY, COMPLETENESS AND USEFULNESS OF CUSTOMER DATA AND
        ANY INFORMATION OBTAINED THROUGH THE USE OF THE WEBSITE OR THE BEAMS PLATFORM, FOR MAKING
        AND IMPLEMENTING DECISIONS BASED ON SUCH INFORMATION, AND FOR HANDLING ANY RELATED
        CONSEQUENCES. ANY SUCH INFORMATION HANDLED BY THE CUSTOMER THROUGH THE WEBSITE OR THE BEAMS
        PLATFORM IS NOT RESPONSIBLE FOR BY BEAMS.
      </p>
      <p>5. Intellectual Property Rights</p>
      <p>
        5.1 You accept that We are the sole owners of all intellectual property rights in and to the
        Software, Service, and all changes and additions of either, and that these rights are
        protected by European, American, and international intellectual property laws.
      </p>
      <p>
        5.2 If and to the extent that any information submitted, entered, or uploaded to the Service
        by You or by Us on Your behalf relates to You personally, You retain all rights, titles, and
        interests in and to such information. Any information pertaining to your data that can be
        used to identify you personally does not belong to us.
      </p>
      <p>
        5.3 We maintain all rights, titles, and interests in transactional and performance data
        connected to the usage of the Service that We may gather, use, and disclose for Our business
        reasons, provided that such use does not expose any Information that is personally
        identifiable (including software use optimization and product marketing).
      </p>
      <p>
        5.4 Regardless of whether You had any creative or constructive suggestions for such
        modifications and/or improvements, We retain all rights, titles, and interests in and to the
        Software or Service and all modifications and/or upgrades thereto.
      </p>
      <h2>6. Payment Terms</h2>
      <p>
        6.1. Monthly Terms: Unless otherwise specified, Your monthly Service membership will
        automatically renew each month until terminated in accordance with Section 9 of this
        agreement.
      </p>
      <p>
        6.2. Annual Terms: A one-year minimum commitment is required for an annual subscription
        price. We reserve the right to invoice you during the first month of the annual commitment
        period. Before the end of the annual commitment period, if your subscription is suspended
        for non-payment, you will no longer be eligible for the yearly subscription pricing for that
        period, and you will be charged the difference between the monthly and annual commitment
        pricing for the number of months your subscription was active during that period. Your
        annual subscription will automatically renew for a further year on the anniversary date,
        unless you tell us otherwise.
      </p>
      <p>6.3 You&apos;ll get an email reminder for the bill.</p>
      <p>
        6.4 All payments must be made within fourteen (14) days of the invoice&apos;s due date. They
        must be paid by credit card, direct debit, or wire transfer using the bank details listed on
        the invoice.
      </p>
      <p>
        6.5 Your access to the Service may be suspended if You don&apos;t pay the invoice within
        fourteen (14) days of receiving the email. In order to regain access, get in touch with us
        and settle any unpaid bills.
      </p>
      <p>
        6.6 The quantity of initial user licenses for Your subscription is shown on Your initial
        order form. The number of user licenses can then be increased by adding additional users
        directly to your Beams account. Based on your per user pricing prorated until the end of the
        term, you will be charged for the additional users.
      </p>
      <p>
        6.7. We have the right to annually increase the fees for Your membership to account for
        increases in staff costs or other costs. We will send you a text message notifying you of
        these price alterations and the time they will take effect. For the times that You have
        already paid for, the price modifications shall not be applicable.
      </p>
      <h2>7. Term and Termination</h2>
      <p>
        7.1 The duration of these TOS (the &quot;Term&quot;) begins on the &quot;Effective
        Date&quot; when you subscribe to the Service and lasts until all subscriptions granted in
        accordance with these TOS expire or are terminated. The TOS will expire at the end of the
        trial time if You choose to use the Service for a free trial period and do not buy a
        subscription before then.
      </p>
      <p>
        7.2 The user subscriptions that You acquire begin on the start date indicated in the
        relevant order and last for the duration of the subscription period indicated in that order.
      </p>
      <p>
        7.3. To cancel Your subscription, send an email to support@beams.ai. If the subscription is
        for a monthly term, you may cancel it by giving fourteen (14) days&apos; notice before the
        end of the term. If the subscription is for an annual period, you may cancel it by giving
        two (2) months&apos; notice before the end of the relevant term (Section 4). When canceling
        a monthly, yearly, or other membership, there are no returns or credits given.
      </p>
      <p>
        7.4 If We have a good faith opinion that You have broken any of the TOS and You have not
        remedied the violation in question after receiving notice from Us, We may terminate or
        suspend Your access to the Service without incurring any obligation.
      </p>
      <p>
        7.5.The option to terminate an agreement for good reason and without prior notice is
        unaffected.
      </p>
      <p>
        7.6 For a termination notice to be effective, it must be in text form (such as an email).
      </p>
      <p>
        7.7 Upon Your request made within thirty (30) days of the termination&apos;s effective date,
        We shall provide You with a file containing Your data in comma separated value (.csv)
        format. After such thirty (30) day period, We shall not be obligated to keep or provide any
        of Your data, and shall be free to delete all of Your data from Our systems, as well as any
        other data that may be in Our possession or control, unless such deletion is forbidden by
        applicable law.
      </p>
      <h2>8. Miscellaneous</h2>
      <p>
        8.1 Rule of law. Without regard to EU laws&apos; principles governing conflicts of laws,
        these TOS shall be governed by and construed in accordance with EU law. The United Nations
        Convention on Contracts for the International Sale of Goods shall in no way apply to these
        TOS.
      </p>
      <p>
        8.2 The location of the court. Any disagreements between the parties that cannot be
        addressed amicably shall be resolved by the EU courts&apos; jurisdiction.
      </p>
      <p>
        8.3 Assignment. Without the other&apos;s prior written approval, none of Us will be able to
        assign Our rights or responsibilities under these TOS. This Agreement shall be void in the
        event of any such assignment.
      </p>
      <p>
        8.4. If any part of this TOS is found to be wholly or partially defective or unenforceable,
        the remaining portions shall remain valid and enforceable.
      </p>
    </div>
  );
}
