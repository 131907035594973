import React, { useRef } from 'react';

import { Box, Button, Container, Grid } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import imgArrowRightW from 'static/landing/i_landing_arrow_right_w.svg';
import imgArrowRight from 'static/landing/i_landing_arrow_right.svg';
import imgFeatureArrow from 'static/landing/i_landing_arrow_smooth.svg';

export default function LandingSection4() {
  const containerRef = useRef();

  return (
    <Box ref={containerRef} style={{ overflow: 'scroll' }}>
      <Container maxWidth='lg' className={styles.section4Container}>
        <Box className={styles.section4Wrapper}>
          <Box className={styles.section4TitleWrapper}>
            <Box className={styles.sectionTitle}>Before Beams</Box>
            <Box className={styles.sectionTitle}>After Beams</Box>
          </Box>
          <Box className={styles.section4FeatureRow}>
            <Box>Tedious process of creating and distributing surveys</Box>
            <img
              alt='arrow right'
              className={styles.hideSM}
              style={{ width: '1.8rem', marginLeft: '1.8rem' }}
              src={imgFeatureArrow}
            />
            <Box>
              <strong>Automatically created and distributed surveys</strong>
            </Box>
          </Box>
          <Box className={styles.section4FeatureRow}>
            <Box>Losing the best employees because of overlooked issues</Box>
            <img
              alt='arrow right'
              className={styles.hideSM}
              style={{ width: '1.8rem', marginLeft: '1.8rem' }}
              src={imgFeatureArrow}
            />
            <Box>
              <strong>Increasing employee retention</strong>
            </Box>
          </Box>
          <Box className={styles.section4FeatureRow}>
            <Box>Struggling to figure out how employees actually feel</Box>
            <img
              alt='arrow right'
              className={styles.hideSM}
              style={{ width: '1.8rem', marginLeft: '1.8rem' }}
              src={imgFeatureArrow}
            />
            <Box>
              <strong>Getting a clear picture of a team&apos;s overall well-being</strong>
            </Box>
          </Box>
          <Box className={styles.section4FeatureRow}>
            <Box>Ongoing costs for mental health and burnout support</Box>
            <img
              alt='arrow right'
              className={styles.hideSM}
              style={{ width: '1.8rem', marginLeft: '1.8rem' }}
              src={imgFeatureArrow}
            />
            <Box>
              <strong>Maintaining your employees&apos; happiness without spending a dime</strong>
            </Box>
          </Box>
          <Box className={styles.section4FeatureRow}>
            <Box>Decreased productivity caused by miscommunication and a lack of feedback</Box>
            <img
              alt='arrow right'
              className={styles.hideSM}
              style={{ width: '1.8rem', marginLeft: '1.8rem' }}
              src={imgFeatureArrow}
            />
            <Box>
              <strong>
                A higher-performing team receiving regular feedback and acknowledgment
              </strong>
            </Box>
          </Box>
          <Box className={`${styles.section4SwitchFlex} ${styles.showSM}`}>
            <Box
              className={styles.section4SwitchButton}
              onClick={() => (containerRef.current.scrollLeft = 1000)}>
              Switch to After Beams
              <img alt='move to right icon' src={imgArrowRight} />
            </Box>
            <Box
              className={`${styles.section4SwitchButton} ${styles.section4ArrowReverse}`}
              onClick={() => (containerRef.current.scrollLeft = 0)}>
              <img alt='move to left icon' src={imgArrowRightW} />
              Switch to Before Beams
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
