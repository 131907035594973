import { SnackbarContent, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import CloseIcon from '@mui/icons-material/Close';
import { Card, CardActions, IconButton, Typography } from '@mui/material';

import styles from 'helpers/WhiteToast.module.scss';

import { iconInfoBlue } from 'features/shared/Icons';

const SnackMessage = forwardRef(function SnackMessage(props, ref) {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const renderTime = ({ remainingTime }) => {
    return (
      <div className={styles.timer}>
        <div className={styles.value}>{remainingTime}</div>
      </div>
    );
  };

  return (
    <SnackbarContent
      ref={ref}
      sx={{
        minWidth: {
          sm: '344px !important'
        }
      }}
      classes={{ root: styles.root }}
      // className={styles.root}
    >
      <Card className={styles.card}>
        <CardActions className={styles.actionRoot}>
          {props.timer && (
            <div className={styles.timerwrapper}>
              <CountdownCircleTimer
                size={24}
                strokeWidth={2}
                isPlaying
                duration={5}
                colors={['#5484FF', '#FFCC53', '#FF685E']}
                colorsTime={[4, 3, 0]}
                onComplete={() => handleDismiss()}>
                {renderTime}
              </CountdownCircleTimer>
            </div>
          )}
          {!props.timer && (
            <img width='24' height='24' style={{ marginRight: '15px' }} src={iconInfoBlue} />
          )}
          <Typography variant='subtitle2' className={styles.typography}>
            {props.message}
          </Typography>
          <div className={styles.icons}>
            {/* <IconButton
              aria-label='Show more'
              className={`${styles.expand} ${ expanded ? styles.expandOpen : '' }`}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton> */}
            {props.action && <div className={styles.actionWrapper}>{props.action}</div>}
            {!props.hideCloseButton && (
              <IconButton className={styles.expand} onClick={handleDismiss}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </CardActions>
        {/* <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Paper className={classes.collapse}>
            <Typography gutterBottom>PDF ready</Typography>
            <Button size='small' className={classes.button}>
              <CheckCircleIcon className={classes.checkIcon} />
              Download now
            </Button>
          </Paper>
        </Collapse> */}
      </Card>
    </SnackbarContent>
  );
});

export default SnackMessage;
SnackMessage.propTypes = {
  id: PropTypes.number,
  message: PropTypes.string.isRequired,
  action: PropTypes.node,
  timer: PropTypes.number,
  hideCloseButton: PropTypes.bool
};
