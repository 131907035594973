import React from 'react';

import styles from 'features/landing/LandingText.module.scss';

export default function LandingPrivacyPolicyPage() {
  return (
    <div className={styles.wrapper}>
      <h1>Privacy Policy</h1>
      <p>
        Beams offers tools and platform commercialized as &quot;Beams.ai&quot;
        which increases employee engagement. We understand the importance of
        protecting Personal Information (as defined below). For this reason,
        Beams strives to have business procedures and security safeguards in
        place to protect Personal Information under its control.
      </p>
      <h2>Application and Scope</h2>
      <p>
        This privacy statement outlines Beams procedures for the gathering,
        using, and disclosing of personal information.
      </p>
      <p>
        The purpose of this privacy statement (the &quot;Policy&quot;) is to
        develop ethical and open procedures for the management of personal data
        and to conform to all applicable laws. In accordance with the Beams
        Terms of Service (available at &lt;link&gt;) (the &quot;Terms&quot;),
        which were agreed to by Beams and its corporate customers (each, a
        &quot;Customer&quot;), Beams is required to adhere to certain standards,
        responsibilities, and obligations with regard to any Personal
        Information it collects, accesses, or processes in the course of its
        business operations.
      </p>
      <p>
        This Policy also applies to Personal Information collected about Users
        of Beams&apos; website (as defined in the Terms) and describes how Beams
        uses and discloses Personal Information obtained from those who access
        and use{' '}
        <a href='https://www.google.com/url?q=http://www.beams.ai&amp;sa=D&amp;source=editors&amp;ust=1678303709753721&amp;usg=AOvVaw2hdKBzQEYz_ZopsVJ5oIWV'>
          www.beams.ai
        </a>
        &nbsp;and any of its affiliated websites, such as (collectively, the
        &quot;website&quot;), as well as other users of those websites. It also
        describes how Beams employs cookies and related tools. Do not use or use
        the Beams Platform, any associated services (the &quot;Services&quot;),
        or the website if you disagree with this Policy.
      </p>
      <p>
        This Policy also applies to Personal Information collected about Users
        of Beams&apos; website (as that term is defined in the Terms) and
        describes how Beams uses and discloses Personal Information collected
        from visitors to and other users of www.beams.ai and Beams’ affiliated
        websites, such as www.beams.ai (collectively, the &quot;website&quot;).
        Furthermore, it describes how Beams makes use of cookies and other
        similar tools. Do not use the Beams Platform, any associated services
        (the &quot;Services&quot;), or the website if you disagree with this
        Policy.
      </p>
      <h2>Processing of Data, Purpose, and Legal Basis</h2>
      <p>
        We process your personal data in compliance with the guidelines of the
        EU General Data Protection Regulation (GDPR) (BDSG).
      </p>
      <p>
        All of our processing activities have Art. 6 Sec. 1 GDPR as their legal
        foundation. In the framework of the display of the particular processing
        operations, more information will be provided to you.
      </p>
      <h2>Data retention policy</h2>
      <p>
        Beams retains customer data in accordance with industry best practices
        and legal requirements:
      </p>
      <ul>
        <li>
          User Data: Beams.ai retains user data for as long as a user&apos;s
          account is active and for a reasonable period afterward to provide the
          user with access to our services. This includes user profiles,
          preferences, and communication history.
        </li>
        <li>
          Survey and Response Data: We anonymize all survey responses, and it is
          impossible to determine who answered and how. Survey data and
          responses are retained for as long as you maintain an active Beams.ai
          account.
        </li>
        <li>
          Analytics Data: Beams.ai may aggregate and anonymize data for
          analytical purposes. This data is retained indefinitely but is no
          longer associated with individual users.
        </li>
        <li>
          Legal and Compliance Obligations: We may retain certain data to comply
          with legal, regulatory, or contractual requirements, such as tax or
          financial reporting obligations.
        </li>
        <li>
          Account Deletion: Upon account deletion, Beams.ai will promptly delete
          all user data, including personal information, unless retention is
          necessary for legal or compliance reasons.
        </li>
        <li>
          Data Security: Beams.ai is committed to safeguarding users&apos; data
          and employs robust security measures to protect against unauthorized
          access, disclosure, alteration, or destruction.
        </li>
      </ul>
      <h2>Data archival/removal policy</h2>
      <p>
        Beams will remove customer data in accordance with industry standards,
        legal requirements, and the principles of data protection and privacy:
      </p>
      <ul>
        <li>
          Data Removal Request: Upon receiving a data removal request from a
          customer or authorized representative, Beams.ai will promptly initiate
          the data removal process.
        </li>
        <li>
          User-Controlled Deletion: Users can delete their individual data,
          including surveys, responses, and personal information, at any time
          through the Beams.ai platform. This deletion is immediate and
          irreversible. Users can delete specific survey data or responses at
          any time.
        </li>
        <li>
          Account Deletion: Upon account deletion, Beams.ai will initiate the
          removal of all associated user data. This process includes the
          deletion of user profiles, preferences, and communication history.
          Data will be permanently removed unless retention is necessary for
          legal or compliance reasons. Account admins can delete all data
          company-wide at once by themselves.
        </li>
        <li>
          Backup and Archive Data: Beams.ai maintains backup and archive copies
          of customer data to ensure business continuity and data integrity.
          These backup and archive copies may retain customer data for a limited
          period, typically 30 days, after which the data is overwritten or
          deleted.
        </li>
        <li>
          Data Security: Beams.ai is committed to data security and employs
          robust measures to protect data against unauthorized access,
          disclosure, alteration, or destruction during the removal process.
        </li>
        <li>
          Legal and Compliance Obligations: Beams.ai may retain certain data to
          comply with legal, regulatory, or contractual requirements, such as
          tax or financial reporting obligations. In such cases, data will be
          securely stored in compliance with relevant laws.
        </li>
        <li>
          Data Audit and Accountability: Beams.ai conducts periodic audits to
          ensure the effective implementation of data removal procedures and to
          verify compliance with data protection regulations.
        </li>
      </ul>
      <h2>Data storage policy</h2>
      <p>
        Beams will store customer data in accordance with industry standards and
        legal requirements. We retain user data for as long as necessary to
        provide our services and meet ‌compliance obligations. Data security is
        a top priority, and we employ robust measures to protect your
        information. Users can review and manage their data within our platform
        at any time.
      </p>
      <h2>How you can exercise your rights</h2>
      <p>
        To be in control of your personal data, you have the following rights:
      </p>
      <ul>
        <li>
          Accessing / reviewing / updating / correcting your personal data. You
          may request a copy of, as well as updating and correcting your
          personal data collected during your use of the Service at{' '}
          <a href='mailto:support@beams.ai'>support@beams.ai</a>.
        </li>
        <li>
          Deleting your personal data. You can request the erasure of your
          personal data by sending us an email at{' '}
          <a href='mailto:support@beams.ai'>support@beams.ai</a>.
        </li>
        <li>
          When you request the deletion of your personal data, we will use
          reasonable efforts to honor your request. In some cases we may be
          legally required to keep some of the data for a certain time; in such
          event, we will fulfill your request after we have complied with our
          obligations.
        </li>
      </ul>
      <h2>Analytics</h2>
      <p>
        3rd party analytics services allow us to monitor and analyze app usage,
        better understand our audience and user behavior.
      </p>
      <h2>Google Analytics</h2>
      <p>
        Google Analytics is an analysis service provided by Google Inc. Google
        utilizes the collected data to track and examine the use of Apps, to
        prepare reports on user activities and share them with other Google
        services. Google may use the data to contextualize and personalize the
        ads of its own advertising network.
      </p>
      <p>
        <a href='https://www.google.com/url?q=http://www.google.com/intl/en/policies/privacy&amp;sa=D&amp;source=editors&amp;ust=1678303709756231&amp;usg=AOvVaw3XUgUFUAfEY4cojNMPSJT2'>
          http://www.google.com/intl/en/policies/privacy
        </a>
      </p>
      <h2>Amplitude Analytics</h2>
      <p>Amplitude Analytics is an analysis tool provided by Amplitude Inc.</p>
      <p>
        Learn more about&nbsp;how they do collect and process information here:
      </p>
      <p>https://amplitude.com/privacy</p>
      <h2>Changes to our Privacy Policy</h2>
      <p>
        The current Privacy Policy may be changed. We will keep this document up
        to date. Last updated:
      </p>
      <h2>Your consent</h2>
      <p>
        By using the platform you consent to this Privacy Policy as well as all
        the 3rd party Privacy Policies mentioned above.
      </p>
      <h2>Contacting us</h2>
      <p>
        If there are any questions regarding this privacy policy you may contact
        us using the information below.
      </p>
      <p>
        Email: <a href='mailto:support@beams.ai'>support@beams.ai</a>.
      </p>
    </div>
  );
}
