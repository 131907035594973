import { useEffect } from 'react'

export default function MetaTags(props) {
  useEffect(() => {
    document.title = props.title
    document.querySelector('meta[name="description"]').setAttribute('content', props.description);

  }, [props.title, props.description]);

  return null;
}
