import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Button, Container } from '@mui/material';

import styles from 'features/landing/Landing.module.scss';

import LandingCTAButtonGroup from 'features/landing/LandingCTAButtonGroup';

import amplitudeHelper from 'helpers/Amplitude';

import imgArrowLeft from 'static/landing/img_c_arrow_left.svg';
import imgArrowRight from 'static/landing/img_c_arrow_right.svg';
import imgCircleBg from 'static/landing/img_circle_bg.svg';
import imgQuestion1 from 'static/landing/l_carousel_1.webp';
import imgQuestion2 from 'static/landing/l_carousel_2.webp';
import imgQuestion3 from 'static/landing/l_carousel_3.webp';
import imgQuestion4 from 'static/landing/l_carousel_4.webp';

export default function LandingSection5(props) {
  const images = [imgQuestion1, imgQuestion2, imgQuestion3, imgQuestion4];
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [animation, setAnimation] = useState();
  const [shouldCancelTimer, setShouldCancelTimer] = useState(false);

  useEffect(() => {
    if (shouldCancelTimer) {
      return;
    }

    setTimeout(() => {
      handleArrowClick('right', true);
    }, 7000);
  }, [currentPageIndex, shouldCancelTimer]);

  const handleArrowClick = (direction, auto) => {
    let index = currentPageIndex + (direction === 'left' ? -1 : 1);
    if (index < 0) {
      index = images.length - 1;
    }
    if (index > images.length - 1) {
      index = 0;
    }

    if (auto !== true) {
      setShouldCancelTimer(true);
    }

    setCurrentPageIndex(index);
    setAnimation(direction);

    setTimeout(() => {
      setAnimation(null);
    }, 400);
  };

  let imageClassName = null;
  imageClassName = animation === 'left' ? styles.animationRTL : styles.animationLTR;
  if (animation === null) {
    imageClassName = null;
  }

  return (
    <Box className={styles.section5Wrapper} style={{ overflow: 'hidden' }}>
      {images.map((image, index) => {
        return <link key={`section-5-preload-${index}`} rel='preload' as='image' href={image} />;
      })}
      <img
        alt='circle background'
        style={{ width: '160rem' }}
        className={`${styles.section5Bg} ${styles.hideSM}`}
        src={imgCircleBg}
      />
      <Container className={styles.section5Container}>
        <h2 className={styles.sectionTitle} style={{ margin: 0 }}>
          Anticipating your questions
        </h2>
        <Box className={styles.section5Slider}>
          <Box className={styles.section5ArrowWrapper} onClick={() => handleArrowClick('left')}>
            <img alt='arrow left icon' src={imgArrowLeft} />
          </Box>
          {/* {images.map((image, index) => { return (
            <Box className={currentPageIndex === index ? styles.visible : styles.hidden } key={`image-${index}`} >
              { currentPageIndex === index &&
                <Box className={`${imageClassName} ${styles.section5SliderImageWrapper}`}>
                  <img src={image} />
                </Box>
              }
            </Box>
          ); })} */}

          <Box>
            {images.map((image, index) => {
              return (
                <Box
                  className={`${imageClassName} ${styles.section5SliderImageWrapper} ${
                    currentPageIndex === index ? styles.visible : styles.hidden
                  }`}
                  key={`image-${index}`}>
                  <img alt='feature illustration with chat bubbles' src={image} />
                </Box>
              );
            })}
          </Box>
          <Box className={styles.section5ArrowWrapper} onClick={() => handleArrowClick('right')}>
            <img alt='arrow right icon' src={imgArrowRight} />
          </Box>
        </Box>
        <Box className={styles.section5Pager}>
          <Box className={currentPageIndex === 0 && styles.section5PagerActive}></Box>
          <Box className={currentPageIndex === 1 && styles.section5PagerActive}></Box>
          <Box className={currentPageIndex === 2 && styles.section5PagerActive}></Box>
          <Box className={currentPageIndex === 3 && styles.section5PagerActive}></Box>
        </Box>
        <Box className={`${styles.section5Title}`}>
          Start building a more
          <br />
          engaging workspace in less time
        </Box>
        <Box className={styles.section5CTAButtonBox}>
          <LandingCTAButtonGroup
            center
            onClickGetDemo={props.onOpenCalendly}
            placeForAmplitude='main_bottom'
            showInfo
          />
        </Box>
      </Container>
    </Box>
  );
}

LandingSection5.propTypes = {
  onAction: PropTypes.func,
  onOpenCalendly: PropTypes.func
};
